

import { Component,Input,Output,EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { StepperService } from '../../../../../shared/services/stepper.service';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { RiskCaseConstants } from '../../../common-constants';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-step4-issue',
  templateUrl: './step4-issue.component.html',
  styleUrls: ['./step4-issue.component.scss']
})
export class Step4IssueComponent implements OnInit, OnDestroy {
  viewStatus: string;
  searchValue: string;
  FieldHelpData;
  selectedIdentification;
  identificationList = [];
  riskCase: NewRisk;
  @Input() issueFromEvent: boolean = false;
  @Output() newIssueData: EventEmitter<any> = new EventEmitter<any>();

  RiskCaseConstants = RiskCaseConstants;
  @Input() viewButton: boolean = true;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  subscription: Subscription = new Subscription();

  constructor(private stepperService: StepperService,
              private baseService: BaseService) {
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.viewStatus = 'mainView';
    this.riskCase = stepperService.getNewRisk();
    if(this.riskCase.identify_LOD){
      this.selectedIdentification = this.riskCase.identify_LOD;
    }else{
      this.selectedIdentification = 213;
    }
  }

  LodNames = {
    213: 'Yes, self-identified.',
    214: 'No, identified by a 2nd line function.',
    215: 'No, identified by a 3rd line function.',
  };


  ngOnInit(): void {
    this.subscription.add(this.stepperService.closeCurrentStep.subscribe(index => this.stepperService.updateModel(index, this.selectedIdentification, 'identify_LOD')));
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(null, this.selectedIdentification , 'identify_LOD')));
    this.getIdentificationList();
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  nextStep(data) {
    if (!this.riskCase.issue_source_id) {
      if (data == 213) {
        if (this.issueFromEvent) {
          this.newIssueData.emit({ lastWizardStep: 'IDENTIFICATION',index: 4, listOfData: [{ attr: 'identify_LOD', issueData: data }, { attr: 'issue_source_id', data: { 'description': null, 'enum_type': { 'id': 27, 'name': "Issue Source", 'description': null }, 'id': 241, 'name': "Business Unit", } }] })
          return;
        } else {
          this.stepperService.newRisk.issue_source_id = { 'description': null, 'enum_type': { 'id': 27, 'name': "Issue Source", 'description': null }, 'id': 241, 'name': "Business Unit", };
        }
      }
    }

    if (this.issueFromEvent) {
      this.newIssueData.emit({ lastWizardStep: 'IDENTIFICATION',index: 4, listOfData: [{ attr: 'identify_LOD', issueData: data }, { attr: 'issue_source_id', data: { 'description': null, 'enum_type': { 'id': 27, 'name': "Issue Source", 'description': null }, 'id': 241, 'name': "Business Unit", } }] })
    } else {
      this.stepperService.changeIndex(4, data, 'identify_LOD');
    }
  }

  onChangeRB(event) {
    if(!this.riskCase.issue_source_id){
      if(event == 213){
        this.newData.emit({prop: 'issue_source_id', propValue: {   'description': null,   'enum_type': {'id': 27, 'name': "Issue Source", 'description': null},         'id': 241,         'name': "Business Unit",} });

         }else{
          this.newData.emit({prop: 'issue_source_id', propValue: null});

         }

}
    this.newData.emit({prop: 'identify_LOD', propValue: event});

  }
  getIdentificationList(){
    this.baseService.get('enum/items/22').subscribe(res => {

      this.identificationList = res.body.data;
    });
  }
  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;
  }

  tooltip(toolTipontant){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'right',  theme: 'light', content: toolTipontant };
    return tooltipData;

  }
}

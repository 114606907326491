import { RiskCaseConstants } from 'src/app/modules/new-risk/common-constants';
import { BusinessImpact } from '../business-impact.model';
import { Event } from '../event.model';
import { Issue } from '../issue.model';
import { Mandate } from '../mandate.model';
import {LastWizardIndexEnum, NewRisk} from '../new-risk.model';
import { Roles } from '../roles.model';
import { User } from '../user.model';

export class RiscCaseRequest {
  id?: number;
  source_id?: number;
  issue_source_id ?: any;
  type_id ?: any;
  rcOverdue ?: any;
  completion_status ?: any;
  due_date_status ?: any;
  overall_impact_rating ?: any;

  root_cause_description: string;
  latest_assessm_desc : string;
  lesson_learn_description: string;
  re_opened: string;
  assur_comment : string;
  resolution_approach: string;
  close_date: Date;
  parent_event_id: any;
  rcsa_own_comment_dde_date: Date;

  cancel_comment: string;
  due_date_comment: string;
  due_date_status_id?: any;
  risk_manager_comment: string;

  rcsa_own_comment_dde: string;
  close_comment: string;
  submit_comment: string;
  rcsa_own_comment: string;
  initial_due_date: Date;
  completion_status_id ?: any;
  submit_type_id ?: any;

  source_name: string;
  cancel_date: Date;
  submission_date: Date;
  risk_manager_date: Date;
  past_due_date: Date;
  proposed_due_date: Date;
  assur_owner: User;
  cancelled_by: User;
  dde_approver: User;
  completion_approver: User;

  monetary_impacts: any;

  identify_LOD: any;
  primary_parent_id: any;
  primary_parent_type_id: any;
  controls: any[] = [];
  impact_ratings: any[] = [];

  approver: User;
  requestor: User;



  creator: User;

  local_reporting: any;
  action_items: any;
  risk_assessments: any;
  risks: any;

  scenario_link: any;
  compliance_risk_category: any;

  company_function: any;
  root_cause_level_1: any;
  root_cause_level_2: any;


  event_type_level_1: any;
  event_type_level_2: any;
  location: any;

  last_step: number;
  last_wizard_index: LastWizardIndexEnum;
  permalink?: string
  risk_assessment: {
    id: number,
    name?: string,
    description?: string,
  };
  risk: {id?: number,
    description?: string,
    name?: string,
    detail_description?: string,
    folder?: string };
  classification: {
    id: number;
    name?: string;
    description?: string;
  };
  type: {
    id: number;
    name: string;
    description: string;
  };
  status: {
    id: number;
    name?: string;
    description?: string;
  };
  title: string;
  report_period_id: number;
  report_period: string;
  folder_id: number;
  folder: string;
  url: string;
  owner: User;
  delegate: User;
  risk_manager: User;
  rcsa_owner: User;
  rcsa_own_date: Date;
  compliance_coord: User[] = [];
  gross_loss_amount: string;
  net_loss_amount: string;
  governance_representant: User[] = [];
  occurrence_date: Date;
  impact_rating: {
    id: number;
    enum_type: {
      id: number;
      name: string;
      description: string;
    };
    name: string;
    description: string
  };
  received_date: Date;

  target_due_date: Date;
  discovery_date: Date;
  description: string;
  business_impact: {
    id: number,
    enum_type: {
      id: number,
      name?: string,
      description?: string
    },
    name?: string,
    description?: string
  }[] = [];
  legal_entities: {
    id: number,
    name?: string,
    description?: string,
    type?: string,
    mng_respons_owner?: string
  }[] = [];
  outsourcing: {
    id: number,
    enum_type: {
      id: number,
      name?: string,
      description?: string
    },
    name?: string,
    description?: string,
  }

  constructor() {
    this.classification = {
      id: 3
    };
    this.status = {
      id: 1
    };
    this.risk_manager = { user_id :''};
    this.risk_assessment = {id: 0};
    this.risk = null;
  }

  static toModel(resp: RiscCaseRequest): NewRisk {
    const riskCase = new NewRisk();
    riskCase.riskCaseId = resp.id;
    riskCase.local_reporting = resp.local_reporting;
    riskCase.action_items = resp.action_items;
    riskCase.dueDateComment = resp.due_date_comment;
    riskCase.cancel_comment = resp.cancel_comment;
    riskCase.issue_source_id = resp.issue_source_id;
    riskCase.type = resp.type;
    riskCase.rcOverdue = resp.rcOverdue;
    riskCase.completion_status = resp.completion_status;
    riskCase.due_date_status = resp.due_date_status;

    riskCase.overall_impact_rating = resp.overall_impact_rating;
    riskCase.close_date = resp.close_date;
    riskCase.parent_event_id = resp.parent_event_id;
    riskCase.rcsa_own_comment_dde_date = resp.rcsa_own_comment_dde_date;
    riskCase.received_date =resp.received_date;

    riskCase.rcsa_own_date = resp.rcsa_own_date;
    if(resp.identify_LOD){
      riskCase.identify_LOD = resp.identify_LOD.id;

    }
    if(resp.primary_parent_id){
      riskCase.primary_parent_id = resp.primary_parent_id;

    }
    if(resp.primary_parent_type_id){
      riskCase.primary_parent_type_id = resp.primary_parent_type_id;

    }
    if(resp.controls){
      riskCase.controls = resp.controls;

   }
    if(resp.risk_assessments){
    riskCase.risk_assessments = resp.risk_assessments;

      }
    if(resp.impact_ratings){
       riskCase.impact_ratings = resp.impact_ratings;

    }
    riskCase.rcInitialDueDate = resp.initial_due_date;

    riskCase.risk_manager_comment = resp.risk_manager_comment;
    riskCase.rcsa_own_comment_dde = resp.rcsa_own_comment_dde;
    riskCase.close_comment = resp.close_comment;
    riskCase.submit_comment = resp.submit_comment;
    riskCase.rcsa_own_comment = resp.rcsa_own_comment;
    riskCase.source_name = resp.source_name;
    riskCase.cancel_date = resp.cancel_date;
    riskCase.submission_date = resp.submission_date;
    riskCase.risk_manager_date = resp.risk_manager_date;
    riskCase.rcPastDueDate = resp.past_due_date;
    riskCase.proposed_due_date = resp.proposed_due_date;
    riskCase.approver = resp.approver;

    riskCase.risk_manager = resp.risk_manager;
    riskCase.creator = resp.creator;
    riskCase.cancelled_by = resp.cancelled_by;
    riskCase.dde_approver = resp.dde_approver;


    riskCase.completion_approver = resp.completion_approver;
    riskCase.assurOwner = resp.assur_owner;

    riskCase.monetary_impacts = resp.monetary_impacts;



    riskCase.requestor = resp.requestor;



    riskCase.scenario_link = resp.scenario_link;
    riskCase.compliance_risk_category = resp.compliance_risk_category;

    riskCase.event_type_level_1 = resp.event_type_level_1;
    riskCase.event_type_level_2 = resp.event_type_level_2;


    riskCase.root_cause_level_1 = resp.root_cause_level_1;
    riskCase.root_cause_level_2 = resp.root_cause_level_2;
    riskCase.location = resp.location;
    riskCase.company_function = resp.company_function;
    riskCase.root_cause_description = resp.root_cause_description;
    riskCase.resolutionApproach = resp.resolution_approach;

    riskCase.lesson_learn_description = resp.lesson_learn_description;
    riskCase.reOpened = resp.re_opened;
    riskCase.assurComment  = resp.assur_comment ;

    riskCase.rcLatestAssessmDesc  = resp.latest_assessm_desc ;
    riskCase.status = resp.status.id;
    riskCase.lastStep = resp.last_step;
    riskCase.lastWizardStep = resp.last_wizard_index;
    if(resp.risk){
      riskCase.risk = resp.risk;
    }
    riskCase.mandate = new Mandate();
    if(resp.rcsa_owner){
      riskCase.mandate.owner_id = resp.rcsa_owner.user_id;
      riskCase.mandate.owner = resp.rcsa_owner.user_name;
    }
    if(resp.risk_assessments  && resp.risk_assessments.length > 0){
      riskCase.mandate.risk_assessment_id = resp.risk_assessments[0].id;
      riskCase.mandate.global_function = resp.risk_assessments[0].global_function;
      riskCase.mandate.ra_folder = resp.risk_assessments[0].folder;

    }
    if(resp.primary_parent_type_id && resp.primary_parent_type_id.id == 442 && resp.primary_parent_id){
      riskCase.mandate.risk_assessment_id = resp.primary_parent_id;
    }
    if(resp.risk_manager){
      riskCase.mandate.risk_manager_id = resp.risk_manager.user_id;
      riskCase.mandate.risk_manager = resp.risk_manager.user_name;
    }



    if (resp.classification.id == RiskCaseConstants.EVENT) {
      riskCase.event = new Event();
      riskCase.event.title = resp.title;
      riskCase.event.description = resp.description;
      riskCase.event.discoveryDate = resp.discovery_date;
      riskCase.event.occurredDate = resp.occurrence_date;
    }
    if (resp.classification.id == RiskCaseConstants.ISSUE) {
      riskCase.issue = new Issue();
      riskCase.issue.title = resp.title;
      riskCase.issue.description = resp.description;
      riskCase.issue.discoveryDate = resp.discovery_date;
      riskCase.issue.occurredDate = resp.occurrence_date;
    }
    riskCase.roles = new Roles();
    riskCase.roles.eventOwner = resp.owner;
    riskCase.roles.eventDelegate = resp.delegate;
    if (resp.governance_representant) {
      resp.governance_representant.forEach(gc => {
        riskCase.roles.governanceCoordinators.push({
          contact_email: gc.user_email_address,
          contact_id: gc.user_id,
          contact_name: gc.user_name
        });
      })
    }
    riskCase.target_due_date = resp.target_due_date;
    riskCase.legalEntities = resp.legal_entities;
    riskCase.businessImpact = new BusinessImpact();
    if (resp.business_impact) {
      resp.business_impact.forEach((e: any) => {
        let item;
        if(e.enum_type.id == 38){
          item = { id: e.id, name: e.name, description: e.description };
        }
        if(e.enum_type.id == 10){
           item = { bus_impact_id: e.id, bus_impact_name: e.name, group_name: e.description, group_parent: e.enum_type.description };
        }
        if (e.description == 'CorSo') {
          riskCase.businessImpact['corso'].push(item);
        } else if (e.description == 'iptiQ') {
          riskCase.businessImpact['lifeCapital'].push(item);
        } else if (e.description == 'Reinsurance') {
          riskCase.businessImpact['reinsurance'].push(item);
        } else if(e.name == 'No impact on Business Unit(s)'){
          riskCase.businessImpact['noValue'] = item;
        }
      });
    }
    riskCase.outsourcing = resp.outsourcing ? resp.outsourcing.id : null;
    riskCase.permalinkText = resp.permalink;
    riskCase.folder = resp.folder;
    return riskCase;
  }

  static toDto(riskCase: any, statusReview?: boolean): any {
    let req = new RiscCaseRequest();
    let risks = [];
    let risk_assessment = [];
    req.root_cause_description = riskCase.root_cause_description;
    req.lesson_learn_description = riskCase.lesson_learn_description;
    req.re_opened = riskCase.reOpened;
    req.assur_comment  = riskCase.assurComment ;
    req.type  = riskCase.type ;

    req.latest_assessm_desc  = riskCase.rcLatestAssessmDesc ;
    req.resolution_approach = riskCase.resolutionApproach;
    if (riskCase.local_reporting) {
      req.local_reporting = riskCase.local_reporting;
    }
    if (riskCase.risk) {
      riskCase.risk.forEach(element => {
        if(!element.id){
          element.id = element.risk_id;

        }
      });
      risks = riskCase.risk;
    }
    if (riskCase.risk_assessments) {
      risk_assessment = riskCase.risk_assessments;
    }




    if (riskCase.action_items) {
      req.action_items = riskCase.action_items;
    }
    if (riskCase.identify_LOD) {
      req.identify_LOD = {id:riskCase.identify_LOD};
    }
    req.primary_parent_id = riskCase.primary_parent_id;
    req.primary_parent_type_id = riskCase.primary_parent_type_id;
    if (riskCase.controls) {
       req.controls = riskCase.controls;
    }
    if (riskCase.impact_ratings) {
      req.impact_ratings = riskCase.impact_ratings;
   }
    if (riskCase.compliance_risk_category) {
      req.compliance_risk_category = riskCase.compliance_risk_category;
    }
    if (riskCase.scenario_link) {
      req.scenario_link = riskCase.scenario_link;
    }
    if (riskCase.company_function) {
      req.company_function = riskCase.company_function;
    }
    if (riskCase.root_cause_level_1) {
      req.root_cause_level_1 = riskCase.root_cause_level_1;
    }
    if (riskCase.root_cause_level_2) {
      req.root_cause_level_2 = riskCase.root_cause_level_2;
    }

    if (riskCase.monetary_impacts && statusReview) {
      req.monetary_impacts = riskCase.monetary_impacts;
    }


    if (riskCase.event_type_level_1) {
      req.event_type_level_1 = riskCase.event_type_level_1;
    }
    if (riskCase.event_type_level_2) {
      req.event_type_level_2 = riskCase.event_type_level_2;
    }


    if (riskCase.location) {
      req.location = riskCase.location;
    }

    if (riskCase.status) {
      req.status.id = riskCase.status;
    }
    if (riskCase.parent_event_id) {
      req.parent_event_id = riskCase.parent_event_id;


    }
    if (riskCase.close_date) {
      req.close_date = this.correctDate(riskCase.close_date);


    }
    if (riskCase.rcsa_own_comment_dde_date) {
      req.rcsa_own_comment_dde_date = this.correctDate(riskCase.rcsa_own_comment_dde_date);
    }
    if (riskCase.cancel_date) {
      req.cancel_date = req.risk_manager_date = this.correctDate(riskCase.cancel_date);


    }
    if (riskCase.submission_date) {
      req.submission_date = req.risk_manager_date = this.correctDate(riskCase.submission_date);


    }
    if (riskCase.rcInitialDueDate) {
      req.initial_due_date = this.correctDate(riskCase.rcInitialDueDate);


    }
    if (riskCase.risk_manager_date) {
      req.risk_manager_date = this.correctDate(riskCase.risk_manager_date);


    }
    if (riskCase.rcPastDueDate) {
      req.past_due_date = this.correctDate(riskCase.rcPastDueDate);


    }
    if (riskCase.proposed_due_date) {
      req.proposed_due_date = this.correctDate(riskCase.proposed_due_date);


    }
    if (riskCase.creator) {
      req.creator = riskCase.creator;
    }
    if (riskCase.cancelled_by) {
      req.cancelled_by = riskCase.cancelled_by;
    }
    if (riskCase.dde_approver) {
      req.dde_approver = riskCase.dde_approver;
    }
    if (riskCase.completion_approver) {
      req.completion_approver = riskCase.completion_approver;
    }
    if (riskCase.assurOwner) {
      req.assur_owner = riskCase.assurOwner;
    }



    if (riskCase.approver) {
      req.approver = riskCase.approver;
    }
    if (riskCase.risk_manager) {
      req.risk_manager = riskCase.risk_manager;
    }

    if (riskCase.requestor) {
      req.requestor = riskCase.requestor;
    }


    if (riskCase.close_comment) {
      req.close_comment = riskCase.close_comment;
    }
    if (riskCase.received_date) {
      req.received_date = this.correctDate(riskCase.received_date);

    }

    if (riskCase.rcsa_own_date) {
      req.rcsa_own_date = this.correctDate(riskCase.rcsa_own_date);

    }
    if (riskCase.issue_source_id) {
      req.issue_source_id = riskCase.issue_source_id;
    }
    if (riskCase.type_id) {
      req.type_id = riskCase.type_id;
    }
    if (riskCase.due_date_status) {
      req.due_date_status = riskCase.due_date_status;
    }
    if (riskCase.rcOverdue) {
      req.rcOverdue = riskCase.rcOverdue;
    }
    if (riskCase.completion_status) {
      req.completion_status = riskCase.completion_status;
    }
    if (riskCase.overall_impact_rating) {
      req.overall_impact_rating = riskCase.overall_impact_rating;
    }
    if (riskCase.cancel_comment) {
      req.cancel_comment = riskCase.cancel_comment;
    }
    if (riskCase.dueDateComment) {
      req.due_date_comment = riskCase.dueDateComment;
    }
    if (riskCase.risk_manager_comment) {
      req.risk_manager_comment = riskCase.risk_manager_comment;
    }
    if (riskCase.rcsa_own_comment_dde) {
      req.rcsa_own_comment_dde = riskCase.rcsa_own_comment_dde;
    }
    if (riskCase.submit_comment) {
      req.submit_comment = riskCase.submit_comment;
    }
    if (riskCase.rcsa_own_comment) {
      req.rcsa_own_comment = riskCase.rcsa_own_comment;
    }




    req.last_step = riskCase.lastStep;
    req.last_wizard_index = riskCase.lastWizardStep;
    //mandate
    req.risk_assessment= null;
    if(riskCase.mandate.risk_manager.user_id){
      req.risk_manager.user_id = riskCase.mandate.risk_manager.user_id;
      req.risk_manager.user_name = riskCase.mandate.risk_manager.user_name;
    }else{
      req.risk_manager.user_id = riskCase.mandate.risk_manager_id;
      req.risk_manager.user_name = riskCase.mandate.risk_manager;
    }


    if (riskCase.event) {
      req.classification.id = RiskCaseConstants.EVENT;
      req.title = riskCase.event.title;
      req.description = riskCase.event.description;
      req.discovery_date =  this.correctDate(riskCase.event.discoveryDate);
      req.occurrence_date =  this.correctDate(riskCase.event.occurredDate);

    }
    if (riskCase.issue) { //issue
      req.classification.id = RiskCaseConstants.ISSUE;
      req.title = riskCase.issue.title;
      req.description = riskCase.issue.description;

    }

    req.risk = null;
    if (riskCase.roles) {
      req.owner = { user_id: riskCase.roles.eventOwner.user_id };
      if (riskCase.roles.eventDelegate) {
        req.delegate = { user_id: riskCase.roles.eventDelegate.user_id };
      }else{req.delegate = null;}
      if( riskCase.roles.governanceCoordinators){
        riskCase.roles.governanceCoordinators.forEach(item => {
          req.governance_representant.push({ user_id: item.contact_id, user_name: item.contact_name, user_email_address: item.contact_email });
        });
      }
    }else{
      if(riskCase.creator){
        req.owner = riskCase.creator;

      }
    }

    if (riskCase.businessImpact && riskCase.issue) {

      if(riskCase.businessImpact.corso.length>0){
        req.business_impact = riskCase.businessImpact.corso.map(x => {
          return { id: x.id, enum_type: { id: 38 } };
        })      }
      if(riskCase.businessImpact.lifeCapital.length>0){
        req.business_impact = req.business_impact.concat(riskCase.businessImpact.lifeCapital.map(x => {
          return { id: x.id, enum_type: { id: 38 } };
        }))
      }
      if(riskCase.businessImpact.reinsurance.length>0){
        req.business_impact = req.business_impact.concat(riskCase.businessImpact.reinsurance.map(x => {
          return { id: x.id, enum_type: { id: 38 } };
        }))
       }

      if(riskCase.businessImpact.noValue ){
        if(riskCase.businessImpact.noValue.id){
          req.business_impact = req.business_impact.concat({ id: riskCase.businessImpact.noValue.id, enum_type: { id: 38 }});
        }      }
    }

    if (riskCase.businessImpact && riskCase.event) {

      if(riskCase.businessImpact.corso.length>0){
        req.business_impact = riskCase.businessImpact.corso.map(x => {
          return { id: x.bus_impact_id, enum_type: { id: 10 } };
        })      }
      if(riskCase.businessImpact.lifeCapital.length>0){
        req.business_impact = req.business_impact.concat(riskCase.businessImpact.lifeCapital.map(x => {
          return { id: x.bus_impact_id, enum_type: { id: 10 } };
        }))
      }
      if(riskCase.businessImpact.reinsurance.length>0){
        req.business_impact = req.business_impact.concat(riskCase.businessImpact.reinsurance.map(x => {
          return { id: x.bus_impact_id, enum_type: { id: 10 } };
        }))
       }

      if(riskCase.businessImpact.noValue){
        if(riskCase.businessImpact.noValue.bus_impact_id){
          req.business_impact = req.business_impact.concat({ id: riskCase.businessImpact.noValue.bus_impact_id, enum_type: { id: 10 }});
        }      }
    }
    if (riskCase.legalEntities) {
      req.legal_entities = riskCase.legalEntities.map(x => {
        return { id: x.id };
      });
    }
    if (riskCase.target_due_date) {
      req.target_due_date = this.correctDate(riskCase.target_due_date);

    }
    if (riskCase.outsourcing) {
      req.outsourcing = { id: riskCase.outsourcing, enum_type: { id: 11 } };
    }

    if(riskCase.permalinkText){
      req.permalink = riskCase.permalinkText;
    }

    return {risk_case: req,risks_primary_path:risks,risk_assessments_primary_path:risk_assessment};
  }


  static correctDate(date){
    const selectedDate = new Date(date);
    if( selectedDate.getTimezoneOffset() < 0){
      selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    }
    return selectedDate;

  }
}

<ng-container *ngIf='refreshData'>
    <mat-card class=" mb-2">



        <div class="row mt-4">
            <div class="col-md-3">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            account_circle
                        </span></div>
                    <div>
                        <p class="mb-0">
                            Created By
                        </p>
                        <p class="contantStyle">{{resultGit.creator.user_name}} ({{resultGit.creator.user_id}})
                            <span *ngIf='resultGit.creator.user_status == "INACTIVE"'>(Inactive)</span>



                        </p>
                        <p class="mb-0">Last Modified By</p>
                        <p class="contantStyle">
                            {{resultGit.modified_by.user_name}} ({{resultGit.modified_by.user_id}})
                            <span *ngIf='resultGit.modified_by.user_status == "INACTIVE"'>(Inactive)</span>

                        </p>



                    </div>
                </div>


            </div>
            <div class="col-md-3">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            date_range
                        </span></div>
                    <div>
                        <p class="mb-0">

                            Creation Date

                        </p>
                        <p class="contantStyle">{{showDateSafari(resultGit.creation_date) | oneOrmDate}}

                        </p>
                        <p class="mb-0">Last Modification Date
                        </p>
                        <p class="contantStyle">{{showDateSafari(resultGit.modify_date) | oneOrmDate}}

                        </p>


                    </div>
                </div>





            </div>
            <div class="col-md-3">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            date_range
                        </span></div>
                    <div>
                        <p class="mb-0">

                            Initial Due Date

                        </p>
                        <p class="contantStyle" *ngIf='resultGit.initial_due_date'>
                            {{showDateSafari(resultGit.initial_due_date) | oneOrmDate}}

                        </p>
                        <p class="mb-0">Past Due Date
                        </p>
                        <p class="contantStyle" *ngIf='resultGit.past_due_date'>
                            {{showDateSafari(resultGit.past_due_date) | oneOrmDate}}

                        </p>


                    </div>
                </div>





            </div>
            <div class="col-md-3">
                <div class="d-flex">




                    <div>
                        <p class="mb-0">

                            URL

                        </p>
                        <p class="contantStyle cursor_pointer"> {{resultGit.url}}

                        </p>

                        <p class="mb-0">

                            Overdue

                        </p>
                        <p class="contantStyle cursor_pointer" *ngIf='resultGit.overdue'>
                            {{resultGit.overdue.id == 270 ? 'Yes' : 'No'}}

                        </p>
                        <p class="contantStyle cursor_pointer" *ngIf='!resultGit.overdue'>
                            No

                        </p>

                    </div>
                </div>





            </div>




        </div>
    </mat-card>

    <mat-card id="detail-container">
        <div class="h-100 contactContainer">




            <div class="d-flex justify-content-between headerContainer">
                <div>
                    <p class="contantStyle">
                        <ng-container *ngIf='riskCase.source_name'>
                            {{riskCase.source_name}}</ng-container> {{riskCase.issue.title}}




                    </p>
                    <p>
                        <span [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.mandate))?'required':''">
                            RCSA<span class="required"
                                *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.mandate)'>*</span>
                        </span>:
                        <ng-container *ngIf='resultGit.risk_assessment'>{{resultGit.risk_assessment.name}}, {{resultGit.risk_assessment.description}},
                        </ng-container>
                        {{riskCase.mandate.owner}}


                    </p>



                    <p>
                        <ng-container *ngIf='primaryParent'>

                            <ng-container *ngIf='primaryParent.riskImpactDriver == "RISK"'>
                                Parent Risk: {{primaryParent.rkName}}: {{primaryParent.rkDesc}}
                            </ng-container>
                            <ng-container *ngIf='primaryParent.riskImpactDriver == "CONTROL"'>
                                Parent Control: {{primaryParent.ctrlControlName}}: {{primaryParent.ctrlDescription}}
                            </ng-container>


                        </ng-container>


                    </p>
                    <p *ngIf='resultGit.folder'>
                        Folder: {{resultGit.folder}}

                    </p>
                </div>









            </div>

            <div class="hlup"></div>


            <div class=" contantContainer mt-4">


                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    account_circle
                                </span></div>
                            <div>
                                <p [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner))?'required':''"
                                    class="mb-0">
                                    Issue Owner<span class="required" *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner)'>*</span>

                                </p>
                                <p class="contantStyle">{{riskCase.roles.eventOwner.user_name}}
                                    <span *ngIf='riskCase.roles.eventOwner.user_status == "INACTIVE"'>(Inactive)</span>




                                </p>
                                <p class="mb-0">Delegate</p>
                                <p *ngIf='riskCase.roles.eventDelegate' class="contantStyle">
                                    {{riskCase.roles.eventDelegate.user_name}}
                                    <span *ngIf='riskCase.roles.eventDelegate.user_status == "INACTIVE"'>(Inactive)</span>

                                </p>
                                <p *ngIf='!riskCase.roles.eventDelegate' class="contantStyle">

                                </p>
                                <p [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner))?'required':''"
                                    class="mb-0   t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.RCSAOwner, 'right')">
                                    RCSA Owner <span class="required" *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner)'>*</span>

                                </p>
                                <p class="contantStyle" *ngIf='resultGit.rcsa_owner'>{{riskCase.mandate.owner}}
                                    <span *ngIf='resultGit.rcsa_owner.user_status == "INACTIVE"'>(Inactive)</span>




                                </p>

                            </div>
                        </div>


                    </div>

                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    date_range
                                </span></div>
                            <div>

                                <p class="mb-0" [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.target_due_date))?'required':''">
                                    Target Due Date <span class="required" *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],riskCase.target_due_date)'>*</span>

                                </p>
                                <p class="contantStyle" *ngIf='riskCase.target_due_date'>
                                    {{showDateSafari(riskCase.target_due_date) | oneOrmDate}}


                                </p>

                                <p *ngIf='resultGit.submission_date' class="mb-0">
                                    Submission Date
                                </p>
                                <p *ngIf='resultGit.submission_date' class="contantStyle">
                                    <ng-container *ngIf='resultGit.submission_date'>
                                        {{showDateSafari(resultGit.submission_date) | oneOrmDate}}</ng-container>

                                </p>

                                <p *ngIf='resultGit.close_date' class="mb-0">
                                    Completion Date
                                </p>
                                <p *ngIf='resultGit.close_date' class="contantStyle">
                                    <ng-container *ngIf='resultGit.close_date'> {{resultGit.close_date | oneOrmDate}}
                                    </ng-container>
                                </p>
                                <p *ngIf='resultGit.cancel_date' class="mb-0">
                                    Cancellation Date
                                </p>
                                <p *ngIf='resultGit.cancel_date' class="contantStyle">
                                    <ng-container *ngIf='resultGit.cancel_date'> {{showDateSafari(riskCase.cancel_date) | oneOrmDate}}

                                    </ng-container>
                                </p>




                            </div>
                        </div>










                    </div>






                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    daccess_time
                                </span></div>
                            <div>
                                <p class="mb-0">
                                    Status
                                </p>
                                <p class="contantStyle"> {{riskStatus}}

                                </p>

                                <p class="mb-0">
                                    Op Risk Manager * (read only)

                                </p>
                                <p class="contantStyle">
                                    {{riskCase.mandate.risk_manager}}
                                    <span *ngIf='resultGit.risk_manager.user_status == "INACTIVE"'>(Inactive)</span>

                                </p>
                                <ng-container *ngIf='resultGit.identify_LOD.id!= 213'>
                                    <p [ngClass]="(checkRequired((resultGit.identify_LOD.id!= 213 )?[ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed]:[],riskCase.assurOwner ))?'required':''"
                                        class="mb-0">
                                        Assurance Owner <span class="required" *ngIf='checkRequired((resultGit.identify_LOD.id!= 213)?[ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed]:[],riskCase.assurOwner )'>*</span>

                                    </p>
                                    <p *ngIf='riskCase.assurOwner' class="contantStyle">
                                        {{riskCase.assurOwner.user_name}}
                                        <span *ngIf='riskCase.assurOwner.user_status == "INACTIVE"'>(Inactive)</span>

                                    </p>
                                    <p *ngIf='!riskCase.assurOwner' class="contantStyle">

                                    </p>

                                </ng-container>

                            </div>
                        </div>


                    </div>



                </div>
                <div class="hlup"></div>



                <div class="hlup"></div>


                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row w-100  py-4">
                            <div class="col-md-12 dateTitle"></div>

                            <div class="col-md-12 ">

                                <div class="  contantStyle">
                                    <span [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.issue.description))?'required':''"
                                        class=" contantStyle">
                                        Description
                                    </span>
                                    <span class="required" *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],riskCase.issue.description)'>*</span>

                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.issue">
                                        {{riskCase.issue.description}}


                                    </div>
                                </div>
                            </div>















                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/Narrative.png" alt="">
                    </div>
                </div>
                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row w-100  py-4">









                            <div class="col-md-3 my-3">
                                <span class="w-100 contantStyle   t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.ImpactedRisks, 'right')">

                                    Impacted Risks


                                    <span class="readOnly">
                                        (read only)
                                    </span>
                                </span>




                            </div>





                            <div class="col-md-9 my-3">





                                <ng-container>
                                    <div class="fix-radio-position" hideToggle="true">

                                        <div *ngFor='let item of controlRisks; let i =index ' [ngClass]="  item.associationType == 'PRIMARY'   ?'PRIMARYcontrolRisks':''">

                                            <div class='d-flex' style="align-items: center;">

                                                <div class="PRIMARYcontrolRisksTitle"> {{item.rkName}}: {{item.rkDesc}} </div>

                                            </div>

                                            <p class="PRIMARYcontrolRisksContant">Description: {{item.rkDetailDesc}}</p>
                                            <div *ngIf='i != controlRisks.length-1' class="hlup"></div>

                                        </div>

                                    </div>
                                </ng-container>



                            </div>


                            <div class="col-md-3 my-3">
                                <span class="w-100 py-4 contantStyle  " [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed], riskCase.overall_impact_rating    ))?'required':''">

                                    Impact Rating<span class="required"
                                        *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.overall_impact_rating )'>*</span>


                                </span>




                            </div>

                            <div class='col-md-9 my-3'>
                                <ng-container *ngIf='riskCase.impact_ratings.length > 0  && riskCase.overall_impact_rating'>

                                    <div class="fix-radio-position" hideToggle="true">

                                        <div>

                                            <div class='d-flex' style="align-items: center;">

                                                <div class="PRIMARYcontrolRisksTitle"> Issue Impact (calculated):
                                                    <span class="IRResult">{{getResult().msg}}

                                                        </span> </div>

                                            </div>

                                            <div *ngFor='let item of riskCase.impact_ratings' class='d-flex justify-content-start w-100'>
                                                <div class="mr-3">{{getNameOfEnum(item.ir_type_id,irTypeList)}}: </div>
                                                <div>{{getNameOfEnum(item.ir_impact_id,irImpactList)}}</div>

                                            </div>

                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf='riskCase.impact_ratings.length == 0  && riskCase.overall_impact_rating'>

                                    <div class="fix-radio-position" hideToggle="true">

                                        <div>

                                            <div class='d-flex' style="align-items: center;">

                                                <div class="PRIMARYcontrolRisksTitle"> Issue Impact (calculated):
                                                    <span class="IRResult">{{riskCase.overall_impact_rating.name}}

                                                        </span> </div>

                                            </div>



                                        </div>
                                    </div>
                                </ng-container>

                            </div>


                            <div class="col-md-12 my-3 ">

                                <div class="  contantStyle">
                                    <span [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],notYet))?'required':''"
                                        class=" contantStyle">
                                        Latest Assessment
                                    </span><span class="required" *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],notYet)'>*</span>


                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.rcLatestAssessmDesc">
                                        {{riskCase.rcLatestAssessmDesc}}

                                    </div>
                                </div>
                            </div>





                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/Risks.png" alt="">
                    </div>
                </div>

                <div class="hlup"></div>







                <div class="d-flex justify-content-between ">
                    <div class="w-100">
                        <div class="row w-100  py-4">

                            <div class="col-md-12 ">

                                <div class="  contantStyle">
                                    <span [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.resolutionApproach))?'required':''"
                                        class="mb-0 contantStyle">
                                        Agreed Resolution Approach <span class="required"
                                            *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.resolutionApproach)'>*</span>

                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.resolutionApproach">
                                        {{riskCase.resolutionApproach}}

                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 d-flex justify-content-between my-3">
                                <span class=" contantStyle" [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed], (riskCase.action_items.length>0?riskCase.action_items:null)    ))?'required':''">

                                    Actions<span class="required"
                                        *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(riskCase.action_items.length>0?riskCase.action_items:null) )'>*</span>




                                </span>




                            </div>





                            <div class="col-md-12 my-3">





                                <ng-container *ngIf='riskCase.action_items.length > 0'>
                                    <div class="fix-radio-position" hideToggle="true">

                                        <div *ngFor='let item of riskCase.action_items; let i = index '>

                                            <div class='d-flex' style="align-items: center;">

                                                <div class="d-flex justify-content-between w-100">

                                                    <div class="d-flex justify-content-between ">
                                                        <div class='mr-2'>{{item.title}}</div>
                                                        <div class='mr-2'>{{showDateSafari(item.dueDate) | oneOrmDate}}
                                                        </div>
                                                        <div class='mr-2' *ngIf='item.assignee'>{{item.assignee.user_name}} ({{item.assignee.user_id}})</div>

                                                        <div class='mr-2' *ngIf='item.delegate'> {{item.delegate.user_name}} ({{item.delegate.user_id}})</div>

                                                        <div class='mr-2'>{{item.status.name}}</div>
                                                    </div>
                                                    <div>


                                                    </div>
                                                </div>

                                            </div>

                                            <div class="d-flex justify-content-start w-100">

                                                <p class="w-100" *ngIf='item.sourceId'>Description: {{item.desc}}</p>
                                            </div>

                                            <div class="d-flex justify-content-start w-100">
                                                <p class="w-50" *ngIf='item.assignee'> Assignee: {{item.assignee.user_name}} ({{item.assignee.user_id}})
                                                    <span *ngIf='item.assignee.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                                <p class="w-50" *ngIf='item.delegate'>Delegate: {{item.delegate.user_name}} ({{item.delegate.user_id}})
                                                    <span *ngIf='item.delegate.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-start w-100">
                                                <div *ngIf='item.actionTaken' class="w-50 mx-3">
                                                    <p> Action Taken:</p>
                                                    <pre>{{item.actionTaken}}</pre>
                                                </div>
                                                <div *ngIf='item.comments' class="w-50 mx-3">
                                                    <p> Comments:</p>
                                                    <pre>{{item.comments}}</pre>

                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-start w-100">


                                                <p class="w-50" *ngIf='item.dueDate'> Due Date: {{showDateSafari(item.dueDate) | oneOrmDate}}</p>
                                                <p class="w-50" *ngIf='item.completionDate'>Completion Date: {{showDateSafari(item.completionDate) | oneOrmDate}}</p>


                                            </div>


                                            <div class="d-flex justify-content-start w-100">
                                                <p class="w-50" *ngIf='item.status'>Status: {{item.status.name}}</p>

                                            </div>
                                            <div class="d-flex justify-content-start w-100">
                                                <p class="w-50" *ngIf='item.creator'> Created By: {{item.creator.user_name}} ({{item.creator.user_id}})
                                                    <span *ngIf='item.creator.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                                <p class="w-50" *ngIf='item.creationDate'> Creation Date: {{showDateSafari(item.creationDate) | oneOrmDate}}</p>
                                            </div>
                                            <div class="d-flex justify-content-start w-100">

                                                <p class="w-50" *ngIf='item.modifier'>Last Modified By: {{item.modifier.user_name}} ({{item.modifier.user_id}})
                                                    <span *ngIf='item.modifier.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                                <p class="w-50" *ngIf='item.modifDate'> Last Modified Date: {{showDateSafari(item.modifDate) | oneOrmDate}}</p>
                                            </div>



                                            <div *ngIf='i != riskCase.action_items.length-1' class="hlup"></div>

                                        </div>
                                    </div>
                                </ng-container>



                            </div>



                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/managementActions.png" alt="">
                    </div>
                </div>

                <div class="hlup"></div>

                <div class="d-flex justify-content-between ">
                    <div class="w-100">
                        <div class="row w-100  py-4">


                            <div class="col-md-3 ">

                                <div class="  contantStyle">
                                    <span [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.issue_source_id))?'required':''"
                                        class=" contantStyle">
                                        Source
                                    </span><span class="required" *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],riskCase.issue_source_id)'>*</span>

                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.issue_source_id">
                                        <p class="">
                                            {{riskCase.issue_source_id.name}}
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="col-md-3 ">
                                <div class="  contantStyle">
                                    <span [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.identify_LOD))?'required':''"
                                        class=" contantStyle">
                                        Identifying LoD
                                    </span><span class="required" *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.identify_LOD)'>*</span>

                                </div>
                                <div *ngIf='riskCase.identify_LOD'>
                                    {{resultGit.identify_LOD.name}}
                                </div>

                            </div>











                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/identification.png" alt="">
                    </div>
                </div>




                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row minH mt-2 ">

                            <div class="col-md-4 ">

                                <div class="  contantStyle">
                                    <span class=" contantStyle">
                                        Type
                                    </span>


                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.type">
                                        <p class="">
                                            {{riskCase.type.name}}
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="bLeft col-md-7">

                                <span [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed], (riskCase.legalEntities.length>0?riskCase.legalEntities:null)    ))?'required':''"
                                    class="contantStyle">
                                    Impacted Legal Entities<span class="required"
                                        *ngIf='checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(riskCase.legalEntities.length>0?riskCase.legalEntities:null) )'>*</span>
                                </span>


                                <div *ngIf="riskCase.legalEntities.length > 0">
                                    <app-step8 [pdfView]='true' [viewButton]='false' [edit]='false'></app-step8>




                                </div>


                            </div>


                        </div>


                        <div class="row minH my-2 ">
                            <div class="col-md-4">
                                <span [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(BiLists(riskCase.businessImpact).length>0?BiLists(riskCase.businessImpact):null) ))?'required':''"
                                    class="contantStyle">
                                    Impacted Business Units<span class="required"
                                        *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(BiLists(riskCase.businessImpact).length>0?BiLists(riskCase.businessImpact):null))'>*</span>
                                </span>







                                <div class="row">
                                    <div class="col-md-12" *ngFor="let Capital of  BiLists(riskCase.businessImpact)">


                                        <div>
                                            {{Capital.name}}

                                        </div>

                                    </div>

                                </div>

















                            </div>
                            <div class="bLeft col-md-4">

                                <span class="contantStyle" [ngClass]="(checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.outsourcing))?'required':''">
                                    Outsourcing<span class="required"
                                        *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.outsourcing)'>*</span>
                                </span>


                                <div *ngIf='riskCase.outsourcing'>
                                    {{ outsourse(riskCase.outsourcing) }}
                                </div>


                            </div>

                        </div>



                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/ORGANISATIONALATTRIBUTES.png" alt="">
                    </div>
                </div>





                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="row minH my-2 w-100">
                        <div class="col-md-4">


                            <span class="contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.icoferIssue , 'right')">
                                ICOFR
                            </span>

                            <div *ngIf='resultGit.issueIcofrs[0]'>
                                {{resultGit.issueIcofrs[0].icofr1Name}}

                            </div>
                            <div *ngIf='resultGit.issueIcofrs[0]'>
                                {{resultGit.issueIcofrs[0].icofr2Name}}

                            </div>


                        </div>
                        <div class="col-md-4 bLeft">



                            <span class=" contantStyle">
                                Local Reporting
                            </span>






                            <ng-container *ngIf='riskCase.local_reporting'>
                                <div *ngFor='let item of riskCase.local_reporting'>
                                    {{item.name}}

                                </div>
                            </ng-container>


                        </div>
                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/frr.png" alt="">
                    </div>
                </div>












                <div class="hlup"></div>

                <div class="d-flex justify-content-between">

                    <div class="row  w-100">




                        <div class="row w-100  minH mt-2 mx-2">

                            <div class="col-md-8">

                                <span class="contantStyle t-demo__btn">
                                    Compliance Category
                                </span>

                                <ng-container *ngIf='riskCase.compliance_risk_category'>
                                    <div *ngFor='let item of riskCase.compliance_risk_category'>
                                        {{item.name}}

                                    </div>
                                </ng-container>



                            </div>
                        </div>
                        <div *ngIf='resultGit.identify_LOD.id!= 213' class="hlup w-100"></div>

                        <div *ngIf='resultGit.identify_LOD.id!= 213' class="row minH m-2 w-100">

                            <div *ngIf='resultGit.identify_LOD.id!= 213' class="col-md-4 bLeft">


                                <span class="contantStyle">
                                    Assurance Function Comments & Follow-up Review
                                </span>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.assurComment">
                                        {{riskCase.assurComment}}

                                    </div>
                                </div>



                            </div>

                            <div *ngIf='resultGit.identify_LOD.id== 215' class="col-md-4 bLeft">


                                <span class="contantStyle">
                                    Re Opened
                                </span>

                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.reOpened">
                                        {{riskCase.reOpened}}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../../assets/images/summary/secLine.png" alt="">
                    </div>
                </div>

















            </div>


        </div>




    </mat-card>



</ng-container>




<ng-template #template>
</ng-template>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SummaryComponent} from './modules/new-risk/components/summary/summary.component';
import {ExitComponent} from './modules/new-risk/components/exit/exit.component';
import {DiscardComponent} from './modules/new-risk/components/discard/discard.component';
import {MsalGuard} from './shared/services';
import {AttachmentsComponent} from './components/home/attachments/attachments.component';
import {RoutingComponent} from './components/routing/routing.component';
import {
  UnsupportedDeviceComponent
} from "./modules/new-risk/components/unsupported-device/unsupported-device.component";


const routes: Routes = [
  {
    path: '',
    component: RoutingComponent,
    canActivate: [MsalGuard],
    pathMatch: 'full'
  },
  {
    path: 'new-risk',
    loadChildren: './modules/new-risk/new-risk.module#NewRiskModule',
    data: {title: 'Risk Case'},
    canActivate: [MsalGuard]
  },
  {
    path: 'exit/:step-id', data: {title: 'Exit'},
    component: ExitComponent
  },
  {
    path: 'risk-case/:case-type/:risk-case-id',
    data: {title: 'Risk Case Summary'},
    component: SummaryComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'discard', component: DiscardComponent
  },
  {
    path: 'attachments', component: AttachmentsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'unsupported-device', component: UnsupportedDeviceComponent
  },
  {
    path: '**',
    redirectTo: '',
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, OnDestroy, OnChanges} from '@angular/core';
import { StepperService } from '../../../../../shared/services/stepper.service';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { BusinessImpact } from '@shared/models/business-impact.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step7-event',
  templateUrl: './step7-event.component.html',
})
export class Step7EventComponent implements OnInit, OnDestroy, OnChanges{
  @Input() viewButton: boolean = true;
  @Input() BUInput = null;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();


  viewStatus: string;
  selectedRisks: string[] = [];
  riskCase: NewRisk;
  AllCorSo = [];
  AllLifeCapital = [];
  AllReinsurance = [];
  cb1Disabled = false;
  cb2Disabled = false;
  cb3Disabled = false;
  FieldHelpData;
  displaySkipDialog = false;
  subscription: Subscription = new Subscription();

  @ViewChild('noone') noone: ElementRef;
  noOneValue:any;
  type = '';

  constructor(private stepperService: StepperService,
              private baseService: BaseService) {
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.riskCase = stepperService.newRisk;
    if(stepperService.newRisk.issue){
      this.type = 'issue';

      this.baseService.get('enum/items/38').subscribe(res => {
        let bIList = res.body.data;
        this.noOneValue = bIList.filter(item => item.id == 401)[0];
        this.AllReinsurance = bIList.filter(item => item.description == 'Reinsurance' && item.name != 'All Reinsurance');
        this.AllCorSo = bIList.filter(item => item.description == 'CorSo' && item.name != 'All CorSo');
        this.AllLifeCapital = bIList.filter(item => item.description == 'iptiQ' && item.name != 'All iptiQ');
      });
    }
    if(stepperService.newRisk.event){
      this.type = 'event';
      this.baseService.get('business-impacts').subscribe(res => {
        let bIList = res.body.data;
        this.noOneValue = bIList.filter(item => item.bus_impact_id == 211)[0];
        this.AllReinsurance = bIList.filter(item => item.group_name == 'Reinsurance' && item.group_parent == null);
        this.AllCorSo = bIList.filter(item => item.group_name == 'CorSo' && item.group_parent == null);
        this.AllLifeCapital = bIList.filter(item => item.group_name == 'iptiQ' && item.group_parent == null);
      });
    }
    
  }
  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(6, this.riskCase.businessImpact, 'businessImpact')));
  }
  tooltip(toolTipontant){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'right',  theme: 'light', content: toolTipontant };
    return tooltipData;

  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  ngOnChanges(){
    this.riskCase = this.stepperService.newRisk;
    if(this.BUInput){
      this.riskCase.businessImpact = this.BUInput;

    }

  }

  nextStep(data) {
    this.stepperService.changeIndex(6, data, 'businessImpact');
  }

  getList(list, listName){
    this.riskCase.businessImpact[listName] = list;
    this.newData.emit({prop: 'businessImpact', propValue: this.riskCase.businessImpact});
  }

  toggleAllCheckboxes(noone, cb1, cb2, cb3){
    if(noone.checked){
      this.riskCase.businessImpact['corso'] = [];
      this.riskCase.businessImpact['lifeCapital'] = [];
      this.riskCase.businessImpact['reinsurance'] = [];

      cb1.selection.clear();
      cb2.selection.clear();
      cb3.selection.clear();
      this.riskCase.businessImpact['noValue'] = this.noOneValue;
      this.newData.emit({prop: 'businessImpact', propValue: this.riskCase.businessImpact});
    }else{
      this.riskCase.businessImpact['corso'] = [];
      this.riskCase.businessImpact['lifeCapital'] = [];
      this.riskCase.businessImpact['reinsurance'] = [];
      this.riskCase.businessImpact['noValue'] = [];

      this.newData.emit({prop: 'businessImpact', propValue: this.riskCase.businessImpact});

    }
  }

  uncheckNoBI(){
    this.riskCase.businessImpact['noValue'] = null;
    this.newData.emit({prop: 'businessImpact', propValue: this.riskCase.businessImpact});
  }

  toggleSkipDialog(){
    if(this.riskCase.businessImpact['corso'].length > 0 ||
       this.riskCase.businessImpact['lifeCapital'] .length > 0 ||
       this.riskCase.businessImpact['reinsurance'] .length > 0 ||
       this.riskCase.businessImpact['noValue']){
      this.displaySkipDialog = !this.displaySkipDialog;
    } else {
      this.nextStep(null);
    }
  }

  clearData(){
    this.riskCase.businessImpact = new BusinessImpact();
    this.nextStep(this.riskCase.businessImpact);
  }
}

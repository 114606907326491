import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-skip-dialog',
  templateUrl: './skip-dialog.component.html',
})
export class SkipDialogComponent implements OnInit {
  @Input() displaySkipDialog: boolean;
  @Output() hideDialog = new EventEmitter();
  @Output() clearData = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  
  skip(){
    this.clearData.emit();
  }

  cancel(){
    this.hideDialog.emit();
  }
}

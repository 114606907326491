import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PrimengModule } from './primeng/primeng.module';

import { ThemeDetectionService } from './theme-detection.service';

import { SharedModule } from '@shared/shared.module';

import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { LeftSideNavComponent } from './components/left-sidenav/left-sidenav.component';
import { FooterComponent } from './components/footer/footer.component';
import { RightSidenavComponent } from './components/right-sidenav/right-sidenav.component';
import { HomeComponent } from './components/home/home.component';
import { HamburgherMenuComponent } from './components/hamburgher-menu/hamburgher-menu.component';
import { HomeTabsComponent } from './components/home/home-tab/home-tabs.component';
import { MyTodosListComponent } from './components/home/home-tab/my-todos-list/my-todos-list.component';
import { MyRiskCasesComponent } from './components/home/home-tab/my-risk-cases/my-risk-cases.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewRiskModule } from './modules/new-risk/new-risk.module';
import { InfoVideoModalComponent } from './components/home/info-video-modal/info-video-modal.component';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import {MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG} from '@shared/services/constants';
import { environment } from 'src/environments/environment';
import { MsalInterceptorConfig } from '@shared/interceptors/msal.interceptor.config';
import { BaseService, StepperService, MsalBroadcastService, MsalGuard, MsalService} from '@shared/services';
import { MsalGuardConfiguration } from '@shared/services/msal.guard.config';
import { MsalInterceptor } from '@shared/interceptors/msal.interceptor';
import { BreadcrumbComponent } from './components/top-navbar/breadcrumb/breadcrumb.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { RcsaListComponent } from './components/home/home-tab/rcsa-list/rcsa-list.component';
import { AttachmentsComponent } from './components/home/attachments/attachments.component';
import {AppService} from './app.service';

import { FavIconService} from './favicon.service';
import { RoutingComponent } from './components/routing/routing.component';

export function initializeThemeDetection(themeDetectionService: ThemeDetectionService) {
  return () => themeDetectionService.init();
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'dd MMM yyyy',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/' + environment.tenantId + '/',
      redirectUri: window.location.origin
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl + '**', [environment.scope]);
  protectedResourceMap.set('https://graph.microsoft.com/', ['User.Read', 'profile', 'openid', 'offline_access']);
  protectedResourceMap.set(environment.functionsEndpoint + '**', [environment.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    LeftSideNavComponent,
    FooterComponent,
    RightSidenavComponent,
    HomeComponent,
    HamburgherMenuComponent,
    HomeTabsComponent,
    MyTodosListComponent,
    MyRiskCasesComponent,
    InfoVideoModalComponent,
    BreadcrumbComponent,
    RcsaListComponent,
    AttachmentsComponent,
    RoutingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    PrimengModule,
    SharedModule,  BsDropdownModule.forRoot(),
    NewRiskModule,
  ],
  providers: [
    BaseService,
    StepperService,
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },

    // {provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    // {provide: MAT_DATE_LOCALE, useValue: MY_DATE_FORMATS},

    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: resourceProviderFactory,
      deps: [AppService],
      multi: true
    },
    FavIconService,
    ThemeDetectionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeThemeDetection,
      deps: [ThemeDetectionService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function resourceProviderFactory(provider: AppService) {
  return () => provider.domainRedirect();
}

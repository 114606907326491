export class BusinessImpact {
    noValue: any;
    corso : any[] = [];
    lifeCapital: any[] =  [];
    reinsurance: any[] = [];

    asList() : any[]{
        return [].concat(this.noValue,...this.corso, ...this.lifeCapital, ...this.reinsurance);
    }

    asListOfId() : any[]{
        return [].concat(this.noValue.bus_impact_id, this.corso.map(item => item.bus_impact_id), this.lifeCapital.map(item => item.bus_impact_id), this.reinsurance.map(item => item.bus_impact_id));
    }
}
import { Component, OnInit , Output,TemplateRef, EventEmitter, Input, OnChanges } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { StepperService, MsalService,  BaseService } from '@shared/services';
import { Router } from '@angular/router';
import { RiscCaseRequest as RiskCaseMessage } from '@shared/models/dto/risk-case-request.dto';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserValidationRoles as existCheck } from 'src/app/shared/models/validation/userValidations';
import { ActionMsg } from '../../../../../../shared/common/textualDescriptions';
import { IssueStatus, dateFormat } from '@shared/common/constants';

@Component({
  selector: 'app-issue-status-bar',
  templateUrl: './issue-status-bar.component.html',
  styleUrls: ['./issue-status-bar.component.scss']
})
export class IssueStatusBarComponent implements OnInit, OnChanges {
  IssueStatus = IssueStatus;
  @Input() statusOrder: number;
  @Input() owner: boolean;
  @Input() cashOut: boolean;
  @Input() lodId: number;
  @Input() overDue: boolean;
  @Input() typeValidation: any;
  @Input() actionItemAssigneAndDelegate: any;
  @Input() isDesktopDevice: boolean;

  @Output() actionItemValidationCheck: EventEmitter<any> = new EventEmitter<any>();
  @Input() userRole: string;
  @Input() rcsaOwner: string;

  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  dateToday = new Date();
  commentStatus;
  actionTaken;
  ActionMsg;
  @Output() changeStatusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPopUpEdit: EventEmitter<any> = new EventEmitter<any>();
  @Input() statusBarRiskCaseData: any;
  popupMsg = '';
  rcPastDueDate = new Date();
  rcInitialDueDateComment;
  dateFormat = dateFormat;
  modalRef: BsModalRef;
  statusHistory = [];
  position: TooltipPosition = 'above';
  riskCase;
  loggedUser: string;
  comment = '';
  creator = '';
  ownerId = '';
  manager = '';
  showActionForUsers = true;
  lastStatusChange = {action_name: null,  actor_name: null,  id: 0,  rcId: 0,  rcsc_action_comment: null,  rcsc_action_date: null,  rcsc_action_id: null,  rcsc_actor_id: null   ,  rcsc_status: null,  rcsc_status_after:null,  rcsc_status_after_id: null,  rcsc_status_id:null};
  delegate = '';
  changeStatusRequestLoading = false;

  ORM = '';
  rcscStatus = [];
  loggedUserData;
  changeStatusBody = {'id': null,    'rcId': null,    'rcsc_status_id': null,    'rcsc_status_after_id': null,    'rcsc_action_id': null,    'rcsc_action_date' :null,    'rcsc_actor_id': '',    'rcsc_action_comment': ''  };

  GovernanceCoordinator = [];

  showMissing = false;

  statusList = [{id : this.IssueStatus.Draft, name: 'Draft', description :  null, buttonName: 'Submit Draft for Review', comment: 'submit_comment', order: 1},
                {id : this.IssueStatus.DraftPendingReview, name: 'Draft - Pending Review', description :  null, buttonName: 'Submit Draft for Review', comment: '', order: 2},
                {id : this.IssueStatus.Open, name: 'Open', description :  null, buttonName: 'Submit Draft for Review', comment: '', order: 3},
                {id : this.IssueStatus.OpenPendingCompletionApproval, name: 'Open - Pending Completion Approval', description :  null, buttonName: 'Submit Event for Closure', comment: '', order: 6},
                {id : this.IssueStatus.Completed, name: 'Completed', description :  null, buttonName: 'Close Event', comment: 'close_comment', order: 7},
               ];
               allStatusList = [{id : this.IssueStatus.Draft, name: 'Draft', description :  null, buttonName: 'Submit Draft for Review', comment: 'submit_comment', order: 1},
               {id : this.IssueStatus.DraftPendingReview, name: 'Draft - Pending Review', description :  null, buttonName: 'Submit Draft for Review', comment: '', order: 2},
               {id : this.IssueStatus.Open, name: 'Open', description :  null, buttonName: 'Submit Draft for Review', comment: '', order: 3},
               {id : this.IssueStatus.OpenDueDateChangePending, name: 'Open - Due Date Change Pending', description :  null, buttonName: 'Submit Event for Closure', comment: '', order: 4},
               {id : this.IssueStatus.OpenDueDateExtended, name: 'Open - Due Date Extended', description :  null, buttonName: 'Submit Event for Closure', comment: '', order: 5},
               {id : this.IssueStatus.OpenPendingCompletionApproval, name: 'Open - Pending Completion Approval', description :  null, buttonName: 'Submit Event for Closure', comment: '', order:6},

               {id : this.IssueStatus.Completed, name: 'Completed', description :  null, buttonName: 'Close Event', comment: 'close_comment', order: 7},
               {id : this.IssueStatus.Cancelled, name: 'Cancelled', description :  null, buttonName: 'Submit Draft for Review', comment: 'cancel_comment', order: 8}
              ];
  requeredList = [];
  optionalList = [];
  riskCaseAllField = [
    { canCheck: true, fieldName: 'business_impact', fieldType: 'Array', required: [  this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
    title: 'Business Impact', url: '', index: 5 , editable: [], popupName:'businessImpact' ,validation:{status: this.IssueStatus.Open}, chiled: [] },
    { canCheck: true, fieldName: 'classification', fieldType: 'Object', objAttr: 'id', required: [  this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed], title: 'Classification', url: '',
    index: 0 , editable: [], popupName:'' , chiled: [] },
    // { canCheck: true, fieldName: 'company_function', fieldType: 'Object', objAttr: 'id', required: [  this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed],
    // title: 'Function Code', url: '', index: 9 , editable: [], popupName:'Function' , chiled: [] },
   //  { canCheck: true, fieldName: 'compliance_coord', fieldType: 'Array', required: [  this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed], title: 'Compliance Coord', url: '',
   //  index: 0 , editable: [], popupName:'' , chiled: [] },
    { canCheck: true, fieldName: 'delegate', fieldType: 'Object', objAttr: 'user_id', required: [], title: 'Delegate', url: '', index: 0 , editable: [], popupName: 'event' , chiled: [] },
    { canCheck: true, fieldName: 'assur_owner', fieldType: 'Object', objAttr: 'user_id', required: [], title: 'Assurance Owner', url: '', index: 0 , editable: [], popupName: 'assurOwner',
    validation:{status: false} , chiled: [] },
    
    { canCheck: true, fieldName: 'target_due_date', fieldType: 'Date', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
    title: 'Target Due Date', url: '',
    index: 0 , editable: [], popupName: 'target_due_date',validation:{status:this.IssueStatus.Open} , chiled: [] },



    { canCheck: true, fieldName: 'description', fieldType: 'String', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
    title: 'Description', url: '', index: 2 , editable: [], popupName:'description' , validation:{status: this.IssueStatus.Open}, chiled: [] },







    { canCheck: false, fieldName: 're_opened', fieldType: 'String', required: [],
    title: 'Re Opened', url: '', index: 2 , editable: [], popupName:'reOpened' , validation:{status: false, notValid: !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM','GIA'])} , chiled: [] },
    
    { canCheck: false, fieldName: 'assur_comment', fieldType: 'String', required: [],
    title: 'Assurance Function Comments & Follow-up Review', url: '', index: 2 , editable: [], popupName:'assurComment' ,  validation:{status: false, notValid: !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM','GIA'])} ,chiled: [] },









   //  { canCheck: true, fieldName: 'governance_representant', fieldType: 'Array', required: [  this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed], title: 'Governance Representant', url: '',
   //  index: 0 , editable: [], popupName:'' , chiled: [] },
   { canCheck: true, fieldName: 'local_reporting', fieldType: 'Array', required: [ ],
   title: 'Local Reporting', url: '/new-risk/legalentities', index: 6 , editable: [], popupName: 'localReporting' , chiled: [] },
   { canCheck: true, fieldName: 'legal_entities', fieldType: 'Array', required: [ this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
    title: 'Legal Entities', url: '/new-risk/legalentities', index: 6 , editable: [], popupName: 'legalEntities' , chiled: [] },
    // { canCheck: true, fieldName: 'location',  fieldType: 'Object', objAttr: 'id', required: [ this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed], title: 'Location', url: '',
    // index: 9 , editable: [], popupName: 'AdditionalInformation' , chiled: [] },
   
    { canCheck: true, fieldName: 'owner',  fieldType: 'Object', objAttr: 'user_id', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], title: 'Owner', url: '',
    index: 0 , editable: [], popupName:'event' , chiled: [] },
    // { canCheck: true, fieldName: 'rcsa_owner', fieldType: 'Object', objAttr: 'user_id', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], 
    // title: 'RCSA Owner', url: '',
    // index: 0 , editable: [], popupName: 'RCSA' , chiled: [] },
    { canCheck: true, fieldName: 'risk_assessment', fieldType: 'Array', objAttr: 'id', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
     title: 'RCSA', url: '',
    index: 0 , editable: [], popupName: 'RCSA' , chiled: [] },
    { canCheck: true, fieldName: 'outsourcing', fieldType: 'N', required: [ this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], title: 'Outsourcing', url: '',
    index: 0 , editable: [], popupName: 'outsourcing' , chiled: [] },
    { canCheck: true, fieldName: 'action_items', fieldType: 'Array', required: [ this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], title: 'Action Items', url: '',
    index: 0 , editable: [], popupName: 'action-itemOVP' , chiled: [] },
    { canCheck: true, fieldName: 'type', fieldType: 'Object', objAttr: 'id', required: [],
    title: 'Type', url: '', index: 9 , editable: [], popupName: 'issuetype' , chiled: [] },




    { canCheck: true, fieldName: 'overall_impact_rating',fieldType: 'Object', objAttr: 'id', required: [ this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], title: 'Impact Rating', url: '',
    index: 0 , editable: [], popupName: 'impactRating' ,validation:{status: this.IssueStatus.Open},   chiled: [] },





    // { canCheck: true, fieldName: 'root_cause_level_2', fieldType: 'Object', objAttr: 'id', required: [  this.IssueStatus.OpenPendingCompletionApproval ],
    // title: 'Root Cause Level 2', url: '',  index: 9 , editable: [], popupName: 'RootCauses' , chiled: [] },


    // { canCheck: true, fieldName: 'monetary_impacts', fieldType: 'Array', required: [ this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed], title: 'Monetary Impact', url: '',
    // index: 0 , editable: [], popupName: 'monetaryImpact' , chiled: [] },


    { canCheck: true, fieldName: 'compliance_risk_category', fieldType: 'Array', required: [],
    title: 'Compliance Category', index: 6 , editable: [], popupName: 'complianceRiskCategory' },

  

    { canCheck: true, fieldName: 'issue_source_id', fieldType: 'Object', objAttr: 'id', required: [ this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
    title: 'Source', url: '', index: 9 , editable: [], popupName: 'issueSource' ,validation:{status: this.IssueStatus.Open},  chiled: [] },



     { canCheck: true, fieldName: 'risk_manager', fieldType: 'Object', objAttr: 'user_id', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], 
     title: 'Risk Manager', url: '',
     index: 0 , editable: [], popupName: 'Risk' , chiled: [] },

    //  { canCheck: true, fieldName: 'target_due_date', fieldType: 'String', required: [  this.IssueStatus.Open, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed], title: 'Target Due Date', url: '',
    //  index: 0 , editable: [], popupName:'' , chiled: [] },
     { canCheck: true, fieldName: 'title', fieldType: 'String', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], title: 'Title', url: '',
     index: 2 , editable: [], popupName:'' , chiled: [] },


     { canCheck: true, fieldName: 'resolution_approach', fieldType: 'String', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending], 
     title: 'Agreed Resolution Approach', url: '',
     index: 2 , editable: [], popupName:'resolutionApproach' ,validation:{status: this.IssueStatus.Open},  chiled: [] },



 ];

  constructor(private baseService: BaseService, private stepperService: StepperService,
              private modalService: BsModalService, private msalService: MsalService, private router: Router) {
    const NewDtoriskCaseData = RiskCaseMessage.toDto(this.stepperService.newRisk, true);
    this.riskCase = NewDtoriskCaseData.risk_case;
    if(NewDtoriskCaseData.risk_case.primary_parent_type_id){
      if(NewDtoriskCaseData.risk_case.primary_parent_type_id.id == 442 ){
        this.riskCase.risk_assessment = [{id:NewDtoriskCaseData.risk_case.primary_parent_id}];
      }
    }

    if(this.checkList(NewDtoriskCaseData.risk_assessments_primary_path) ){
      if(this.checkList(this.riskCase.risk_assessment)){
        this.riskCase.risk_assessment = this.riskCase.risk_assessment.concat(NewDtoriskCaseData.risk_assessments_primary_path);

      }else{
        this.riskCase.risk_assessment = NewDtoriskCaseData.risk_assessments_primary_path;

      }
    }
    this.setUsers();
    const riskCaseId = this.stepperService.newRisk.riskCaseId;
    if (msalService.getLoggedUser()){
      this.loggedUser = msalService.getLoggedUser().user_id;
      this.loggedUserData = msalService.getLoggedUser();
    }
    this.baseService.get('governance-coordinators/risk-case/' + riskCaseId).subscribe(res => {
      this.GovernanceCoordinator = res.body.data;

    });
    this.ActionMsg = ActionMsg;

  }
  setUsers(){
    if (this.riskCase.creator) {this.creator = this.riskCase.creator.user_id; }
    if (this.riskCase.owner) {this.ownerId = this.riskCase.owner.user_id; }
    if (this.riskCase.rcsa_owner) {this.rcsaOwner = this.riskCase.rcsa_owner.user_id; }

    if (this.riskCase.risk_manager) {this.manager = this.riskCase.risk_manager.user_id; }
    if (this.riskCase.delegate) {this.delegate = this.riskCase.delegate.user_id; }
  }
  showDateSafari(date){
    let newDate;

    if(date && typeof date == "object" && date.length > 0){

      newDate = date.join('/');
      return new Date(newDate);

    }else if(date && typeof date == "string" && date.length > 0){
      newDate = date.substring(0, 10);
      newDate = newDate.replace(/-/g, "/");
      return new Date(newDate);

    }else{

      newDate = date;
      return new Date(newDate);

    }
    }
    checkList(list){
      if(!list){return false;}
      if(list.length ==0){return false;}
      return true;
      }
  ngOnChanges(): void {
    const NewDtoriskCaseData = RiskCaseMessage.toDto(this.stepperService.newRisk, true);
    this.riskCase = NewDtoriskCaseData.risk_case;
    if(NewDtoriskCaseData.risk_case.primary_parent_type_id){
      if(NewDtoriskCaseData.risk_case.primary_parent_type_id.id == 442 ){
        this.riskCase.risk_assessment = [{id:NewDtoriskCaseData.risk_case.primary_parent_id}];
      }
    }

    if(this.checkList(NewDtoriskCaseData.risk_assessments_primary_path) ){
      if(this.checkList(this.riskCase.risk_assessment)){
        this.riskCase.risk_assessment = this.riskCase.risk_assessment.concat(NewDtoriskCaseData.risk_assessments_primary_path);

      }else{
        this.riskCase.risk_assessment = NewDtoriskCaseData.risk_assessments_primary_path;

      }
    }
    if (this.showMissing){
      this.checkValidation(this.riskCaseAllField, this.riskCase, this.statusOrder);
    }
    if (this.riskCase.proposed_due_date || this.statusOrder === this.IssueStatus.OpenDueDateExtended || this.statusOrder === this.IssueStatus.OpenDueDateChangePending ){
   
      this.statusList = [{id : this.IssueStatus.Draft, name: 'Draft', description :  null, buttonName: 'Submit Draft for Review', comment: 'submit_comment', order: 1},
      {id : this.IssueStatus.DraftPendingReview, order: 2, name: 'Draft - Pending Review', description :  null, buttonName: 'Submit Draft for Review', comment: ''},
      {id : this.IssueStatus.Open, order: 3, name: 'Open', description :  null, buttonName: 'Submit Draft for Review', comment: ''},
      {id : this.IssueStatus.OpenDueDateChangePending, order: 4, name: 'Open - Due Date Change Pending', description :  null, buttonName: 'Submit Event for Closure', comment: ''},
      {id : this.IssueStatus.OpenDueDateExtended, order: 5, name: 'Open - Due Date Extended', description :  null, buttonName: 'Submit Event for Closure', comment: ''},
      {id : this.IssueStatus.OpenPendingCompletionApproval, order: 6, name: 'Open - Pending Completion Approval', description :  null, buttonName: 'Submit Event for Closure', comment: ''},

      {id : this.IssueStatus.Completed, order: 7, name: 'Completed', description :  null, buttonName: 'Close Event', comment: 'close_comment'},
     ];





    }
    if (this.statusOrder === this.IssueStatus.Cancelled){
      if(!this.statusList.find(x => x.id === this.IssueStatus.Cancelled)){
         this.statusList.push({id : this.IssueStatus.Cancelled, order: 8, name: 'Cancelled', description :  null, buttonName: 'Submit Draft for Review', comment: 'cancel_comment'});
     }

    }

    this.setUsers();
  }

  ngOnInit(): void {
    this.baseService.get('enum/items/31').subscribe(res => {

      this.rcscStatus = res.body.data;
    });
    this.baseService.get('status-change/' + this.stepperService.newRisk.riskCaseId).subscribe(res => {
      if (res.body.data.length > 0)
      {      this.statusHistory = res.body.data;

             this.lastStatusChange = this.statusHistory[0];}
    });
  }

  submitTo() {
    let submit = '';
    if(existCheck.userRole(this.statusOrder,[this.IssueStatus.DraftPendingReview])){
      submit = (this.statusOrder === this.IssueStatus.DraftPendingReview ? 'Approve/Reject' : 'Close/Reject');
      return submit;
    }

    if(this.statusOrder === this.IssueStatus.Open){
      if(this.showButtonForUser([ this.ownerId,this.delegate])){
        submit = 'Request Completion Approval / Request Due Date Extension';
      }
    }

    if (!existCheck.userRole(this.statusOrder, [this.IssueStatus.Draft])){
      return submit;
    }

    if(this.showButtonForUserRole(['CORM','ORM','ORMII'])){
      submit = (this.statusOrder === this.IssueStatus.Draft ? 'Approve/Cancel' : 'Close');
    }else{
      submit = (this.statusOrder === 23  ? 'Submit/Cancel' : 'Submit');
    }
    return submit;

  }

  existCheckFn(item, list){
   return existCheck.userRole(item,list);
  }

  checkValidation(validationList, object, status) {
  
    const findItem = (element) => element.id === 298;
    const findActionOpen = (element) => element.status.id === 267;
    const findActionClose = (element) => element.status.id === 268;
    this.riskCase = object;
    this.requeredList = [];
    this.optionalList = [];
    this.showMissing = true;
    validationList.forEach(element => {
          if(object.action_items.length == 0){
            this.actionItemValidationCheck.emit(false);
          }
          if(element.fieldName === 'action_items' &&  object.action_items.length > 0 && (this.statusOrder < IssueStatus.Open)){
              element.popupName = 'action-item';
              if(object.action_items.findIndex(findActionOpen)<0 && object.action_items.findIndex(findActionClose) < 0){

                this.requeredList.push({...{value: object.action_items}, ...element });
                this.actionItemValidationCheck.emit(true);
              }else{
                this.actionItemValidationCheck.emit(false);

              }
          }
          if(element.fieldName === 'action_items' &&  object.action_items.length > 0 && (this.statusOrder >= 25 && this.statusOrder != IssueStatus.OpenDueDateChangePending)){
            element.popupName = 'action-item';

            if(object.action_items.findIndex(findActionOpen) >= 0 ){
              this.requeredList.push({...{value: object.action_items}, ...element });
              this.actionItemValidationCheck.emit(true);
            }else{
              this.actionItemValidationCheck.emit(false);

            }
        }
          if(element.fieldName === 'compliance_risk_category' ){
            if(!this.showButtonForUserRole(['CORM','Comp','ORM','ORMII']) ){
              element.popupName = 'cantEdit';
              element.canCheck = false;
            }
            if(this.showButtonForUserRole(['ORM','ORMII'])){
              element.popupName = 'notComp';

            }
          }
          if(element.fieldName === 'type'){
            if(this.typeValidation.issueIcofrs[0]){
              if(this.typeValidation.issueIcofrs[0].icofr1Name == 'EVM' || this.typeValidation.issueIcofrs[0].icofr2Name == 'EVM' || this.typeValidation.issueIcofrs[0].icofr1Name == 'US GAAP' || this.typeValidation.issueIcofrs[0].icofr2Name == 'US GAAP' ){
                element.required = [  this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending]
              }
            }
            if(this.typeValidation.local_reporting.length > 0 && this.typeValidation.local_reporting.findIndex(findItem)<0){
              element.required = [  this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending]
            }
          }

          if( this.lodId != 213 ){
            if(element.fieldName === 're_opened' && this.lodId == 215){
              if(this.showButtonForUserRole(['CORM', 'ORMII', 'ORM','GIA'])){
                element.required = [];
                element.canCheck = true;

              }else{
                element.popupName = 'cantEdit';
                element.canCheck = false;
              }
            }
            if(element.fieldName === 'assurOwner' && this.lodId == 215){
                element.validation.notValid = !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM','GIA']);
            }
            if(element.fieldName === 'assur_owner' ){
              if(this.showButtonForUserRole(['CORM', 'ORMII', 'ORM','GIA'])){
                element.required = [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending];
                element.canCheck = true;

              }else{
                element.popupName = 'cantEdit';
                element.required = [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending];
                element.canCheck = true;
              }
            }
            if( element.fieldName === 'assur_comment'){
              if(this.showButtonForUserRole(['CORM', 'ORMII', 'ORM','GIA'])){
                element.required = [];
                element.canCheck = true;

              }else{
                element.popupName = 'cantEdit';
                element.canCheck = false;
              }
            }
          }
       
          if( this.lodId == 213 ){
            if(element.fieldName === 'assur_owner' || element.fieldName === 'assur_comment'|| element.fieldName === 're_opened'){
              element.canCheck = false;
            }
          }
    });

    if(this.showButtonForUser([this.manager]) && ( status===this.IssueStatus.Draft ) ){
      status = status+1;
    }
  
    const list = [];



    validationList.forEach(element => {
      if(element.canCheck){
        if (!object[element.fieldName]){
          if (element.required.find(x => x === status)){
            this.requeredList.push(element);
          }else{
            this.optionalList.push(element);
          }
        }else{

          const listItem = {...{value: object[element.fieldName]}, ...element };
          if ( (listItem.fieldType === 'Array' || listItem.fieldType === 'String') && listItem.value.length === 0){
            if (listItem.required.find(x => x === status)){
              this.requeredList.push(listItem);
            }else{
              this.optionalList.push(listItem);
            }
          }
          if (listItem.fieldType === 'Object' &&  !listItem.value[listItem.objAttr]){
  
            if (listItem.required.find(x => x === status)){
              this.requeredList.push(listItem);
            }else{
              this.optionalList.push(listItem);
            }
          }
          if (listItem.fieldType === 'Date' &&  listItem.value.toString().length === 0){
  
            if (listItem.required.find(x => x === status)){
              this.requeredList.push(listItem);
            }else{
              this.optionalList.push(listItem);
            }
          }


          if (listItem.fieldType === 'N' &&  !listItem.value){
  
            if (listItem.required.find(x => x === status)){
              this.requeredList.push(listItem);
            }else{
              this.optionalList.push(listItem);
            }
          }

          //rcsa missing primary parent check
          if(listItem.fieldName === 'risk_assessment' && !object.primary_parent_id){
            this.requeredList.push(listItem);
          }
        }
      }
   

    });















    if(this.overDue && (this.statusOrder<25) && object.target_due_date && object.target_due_date.toString().length !== 0 ){
      this.requeredList.push( { canCheck: true, fieldName: 'target_due_date', fieldType: 'Date', required: [this.IssueStatus.Draft, this.IssueStatus.DraftPendingReview, this.IssueStatus.Open, this.IssueStatus.OpenDueDateExtended, this.IssueStatus.OpenPendingCompletionApproval, this.IssueStatus.Completed, this.IssueStatus.OpenDueDateChangePending],
      title: 'Target Due Date', url: '',
      index: 0 , editable: [], popupName: 'target_due_date' , chiled: [] },);

    }

  }
  arrayDiffLists(a, b, id) {

    const bIds = {};
    b.forEach(obj => {
      bIds[obj[id]] = obj;
    });

    // Return all elements in A, unless in B
    return a.filter(obj => {
      return !(obj[id] in bIds);
    });
  }
  goToStep(url){
    this.stepperService.indexSource.next(url);
  }

getActionId(id){
  const actionObject = this.rcscStatus.find(element => element.id === id);
  return actionObject;

}

  changeStatus( statusId, comments, commentsAttr,user?,date?,actionId?,actionStatus?){
    this.changeStatusRequestLoading = true;
    if(actionStatus){

      if(actionStatus == 287){
        this.newData.emit({prop: "due_date_status", propValue: {id: actionStatus}});
  
      }
      if(actionStatus == 288){
        this.newData.emit({prop: "completion_status", propValue: {id: actionStatus}});
        this.newData.emit({prop: "completion_approver", propValue:  this.loggedUserData});

  
      }
      if(actionStatus == 289){
        this.newData.emit({prop: "completion_status", propValue: {id: actionStatus}});
  
      }
    }

    const todayDate = new Date();
    this.showMissing = false;
    let statusObject;
    this.allStatusList.forEach(element => {
      if (element.id === statusId) {
        statusObject = element;
      }
    });
    let statusObjEmit = {status: statusObject, comment: comments, commentAttr: commentsAttr,dateName:null,dateValue:null,userName:null,userValue:null};
    if (user){
      statusObjEmit.userValue =  this.loggedUserData;
      statusObjEmit.userName =  user;
    }
    if (date){
      statusObjEmit.dateValue =  todayDate;
      statusObjEmit.dateName =  date;
      }

    if(actionId){
      this.changeStatusBody.rcId = this.stepperService.newRisk.riskCaseId;
      this.changeStatusBody.rcsc_action_comment = comments;
      this.changeStatusBody.rcsc_action_date =  RiskCaseMessage.correctDate(todayDate);
      this.changeStatusBody.rcsc_actor_id = this.loggedUserData.user_id;
      this.changeStatusBody.rcsc_status_id = this.statusOrder;
      this.changeStatusBody.rcsc_status_after_id = statusId;
      this.changeStatusBody.rcsc_action_id = this.getActionId(actionId).id;

      this.changeStatusEvent.emit({status:statusObjEmit,changeStatus:this.changeStatusBody});

    }else{
      this.changeStatusEvent.emit({status:statusObjEmit,changeStatus:null});

    }
    this.comment = '';
    this.modalRef.hide();

  }
  getCommentAttr(status){
    let attr;
    this.allStatusList.forEach(element => {
      if (element.id === status) {
        attr = element.comment ;
      }
    });
    return attr;
  }
showButtonForUser(validUsersList){
  if (validUsersList.find(x => x === this.loggedUser)){
    return true;
  }else{
    return false;

  }
}
showButtonForUserRole(validUsersList){
  if (validUsersList.find(x => x === this.userRole)){
    return true;
  }else{
    return false;

  }
}


openPopUp(popup){
  let popupData = {popupName:'',validation:{}}
  if (popup.popupName !== ''){
    popupData.popupName = popup.popupName;

    if(popup.validation){
      Object.keys(popup.validation).forEach(key => {
        popupData.validation[key] = popup.validation[key];
      });
    }
    this.openPopUpEdit.emit(popupData);
  }

}
refreshMissingField(newData){
  if (this.showMissing){
    this.checkValidation(this.riskCaseAllField, newData, this.statusOrder);

  }
}

showDate(date){
return new Date(date);
}
openModal(template: TemplateRef<any>) {
  const configs = {
    keyboard: true,
    animated: true,
    class: ' modal-lg modal-primary RCSAModal',
    ignoreBackdropClick: false
  };
  this.modalRef = this.modalService.show(template, configs);
}
cantEdit(event): boolean {
  return false;
}
dateChanged(evt, row) {
  this.rcPastDueDate = evt;

}
proccedChangDueDate(){
  this.changeStatusRequestLoading = true;
  this.newData.emit({prop: "proposed_due_date", propValue: this.rcPastDueDate});

  this.changeStatus(this.IssueStatus.OpenDueDateChangePending,this.comment,"dueDateComment","","",280);
  this.rcPastDueDate = null;
  this.rcInitialDueDateComment = null;
}
getStatusOrder(statusId){
  for (const iterator of this.allStatusList) {
    if(iterator.id === statusId){
      return iterator.order;
    }
  }
}









approveChangeDueDate(){
  this.newData.emit({prop: "target_due_date", propValue: this.riskCase.proposed_due_date});
  this.newData.emit({prop: "due_date_status", propValue: {id: 286}});
  this.newData.emit({prop: "dde_approver", propValue:  this.loggedUserData});

  this.changeStatus(this.IssueStatus.OpenDueDateExtended,this.comment,  "rcsa_own_comment_dde" ,"approver","rcsa_own_comment_dde_date" ,281)
}
getLastStatus(statusId){
  if(statusId){
    return statusId;
  }else{
    if(this.typeValidation.past_due_date){    return 29;
    }
    return 25;
  }
}



tooltip(toolTipontant, position){
  const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: position,  theme: 'light', content: toolTipontant };
  return tooltipData;
}



actionButtonText(){
  if(this.statusOrder === this.IssueStatus.Draft){
    if( (this.showButtonForUser([this.manager]) || this.showButtonForUserRole(["CORM","ORM","ORMII","GIA"])) && this.lodId==215 ){

      return {msg: 'Review, update, approve, or cancel this issue.',show:true};
    }
    if( (this.showButtonForUser([this.manager]) || this.showButtonForUserRole(["CORM","ORM","ORMII"])) && this.lodId!=215){

      return {msg: 'Review, update, approve, or cancel this issue.',show:true};

    }
    if( !this.showButtonForUser([this.manager]) && !this.showButtonForUserRole(["CORM","ORM","ORMII","GIA"])){
      return {msg: 'Check and finalize your entries, send this issue to your op risk manager for approval, or cancel the issue.',show:true};
    }
    if(this.showButtonForUserRole(["CORM","ORM","ORMII"]) && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))){
      return {msg: 'Check and finalize your entries or cancel tis issue.',show:true};

    }
  }

  if(this.statusOrder === this.IssueStatus.DraftPendingReview){
    if( (this.showButtonForUser([this.manager]) ||  this.showButtonForUserRole(["CORM","ORM","ORMII","GIA"])) && this.lodId==215){
      if(this.showActionForUsers && this.isDesktopDevice ){
        this.checkValidation(this.riskCaseAllField,this.riskCase,this.statusOrder);

      }

      return  {msg:'Review, update, approve, or cancel this issue.',show:true};
    }
    if((this.showButtonForUser([this.manager]) ||  this.showButtonForUserRole(["CORM","ORM","ORMII"])) && this.lodId!=215){
      if(this.showActionForUsers && this.isDesktopDevice ){
        this.checkValidation(this.riskCaseAllField,this.riskCase,this.statusOrder);

      }

      return {msg: 'Review, update, approve, or cancel this issue.',show:true};

    }
    if(this.showButtonForUserRole(["CORM","ORM","ORMII"]) && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))){
      return {msg: 'Check and finalize your entries or cancel tis issue.',show:true};

    }
    return {msg: 'Update or cancel this Issue',show:true};

  }

  if(this.statusOrder === this.IssueStatus.Open){
    if( this.showButtonForUser([ this.ownerId,this.delegate])|| (this.showButtonForUserRole(["GIA"]) &&  this.lodId==215 )){
      return  {msg:'Check your entries, request completion approval or due date extension',show:true};
    }
 
  }

  if(this.statusOrder === this.IssueStatus.OpenDueDateChangePending){
    if( this.showButtonForUser([this.rcsaOwner])){
      if(this.showActionForUsers && this.isDesktopDevice ){
        this.checkValidation(this.riskCaseAllField,this.riskCase,this.statusOrder);

      }

      return {msg: 'Review, approve or reject due date change request',show:true};
    }
  }


  if(this.statusOrder === this.IssueStatus.OpenDueDateExtended){
    if( this.showButtonForUser([ this.ownerId,this.delegate])|| (this.showButtonForUserRole(["GIA"]) &&  this.lodId==215 )){
      return  {msg:'Check your entries, request completion approval or due date extension',show:true};
    }
  }



  if(this.statusOrder === this.IssueStatus.OpenPendingCompletionApproval){
    if( this.showButtonForUser([this.rcsaOwner])){
      if(this.showActionForUsers && this.isDesktopDevice ){
        this.checkValidation(this.riskCaseAllField,this.riskCase,this.statusOrder);

      }

      return {msg: 'Review, update and approve completion of this issue',show:true};
    }
  }


  return {msg:"",show:false};
}
openCommentPopup(msg,action,comm,modal){
  this.commentStatus = comm;
  this.popupMsg = msg;
  this.actionTaken = action;
  const configs = {
    keyboard: true,
    animated: true,
    class: ' modal-lg modal-primary confermationModal',
    ignoreBackdropClick: false
  };
  this.modalRef = this.modalService.show(modal, configs);
}
byteCount(textValue, maxlength) {
  if(!textValue){
    return {valid:true,length:0 };

  }
  if(encodeURI(textValue).split(/%..|./).length - 1 > maxlength){
    return {valid:false,length:encodeURI(textValue).split(/%..|./).length - 1 };

  }
  return {valid:true,length:encodeURI(textValue).split(/%..|./).length - 1 };
}

}

import { Component, Input,Output,EventEmitter } from '@angular/core';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { MsalService ,  StepperService } from '@shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-6-issue',
  templateUrl: './step6-issue.component.html',
  styleUrls: ['./step6-issue.component.scss']
})
export class Step6IssueComponent {
  viewStatus: string;
  today30 = new Date(); // Now
  @Input() issueFromEvent: boolean = false;
  @Output() newIssueData: EventEmitter<any> = new EventEmitter<any>();
  riskCase: NewRisk;
  loggedUser;
  constructor(private msalService: MsalService,private router: Router,private stepperService: StepperService) {
    this.viewStatus = 'mainView';
    this.riskCase = stepperService.newRisk;
    this.loggedUser = msalService.getLoggedUser().user_id;
    this.today30.setDate(this.riskCase.creationDate.getDate() + 30);

  }

  nextStep() {

    if(this.issueFromEvent){
      this.newIssueData.emit(
        {lastWizardStep: 'DONE', index:99,listOfData:
          []
        }
          )
    }else{


      this.riskCase.lastStep = 99;
      this.stepperService.updateRiskCase(this.riskCase);     }

  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }
  getDate(date){
    return new Date(date);
  }
}

import { Component , Input, EventEmitter, Output, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { BaseService } from 'src/app/shared/services';
import { StepperService } from '../../../../shared/services/stepper.service';
import { TreeNode } from 'primeng';
import { Subscription } from 'rxjs';
import structuredClone from '@ungap/structured-clone';

@Component({
  selector: 'app-step8',
  templateUrl: './step8.component.html',
  styleUrls: ['./step8.component.scss']
})

export class Step8Component implements OnInit, OnDestroy, OnChanges{
  @Input() viewButton: boolean = true;
  @Input() edit: boolean = true;
  @Input() pdfView: boolean;

  @Output() newData: EventEmitter<any> = new EventEmitter<any>();

  legalEntities: TreeNode[] = [];
  legalEntitiesAllStatus: TreeNode[] = [];
  legalEntitiesBackup: TreeNode[] = [];
  checkedNodes: TreeNode[] = [];
  selectedNodes: TreeNode[] = [];
  savedLE;
  selectedNodesView: TreeNode[] = [];
   riskCase :NewRisk;
  rcsaSelected = false;
  biSelected = false;
  rcsaFiltered = false;
  biFiltered = false;
  FieldHelpData;
  managerFiltered: any;
  flatLE = [];
  searchByWordString: string = '';
  managerList: any[] = [{ label: 'Filter by Mgmt. Responsible Owner', value: '' }];
  countries: any[];
  filteredCountries = [];
  selectedCountry;
  regions: any[];
  filteredRegions = [];
  selectedRegion;

  displaySkipDialog = false;
  subscription: Subscription = new Subscription();

  constructor(private stepperService: StepperService,
              private baseService: BaseService) {


    this.riskCase = stepperService.getNewRisk();
    this.savedLE = this.riskCase.legalEntities;
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.baseService.get('countries').subscribe(res => {
      this.countries = res.body.data;
      this.filteredCountries.push(...this.countries);
    });

    this.baseService.get('regions').subscribe(res => {
      this.regions = res.body.data;
      this.filteredRegions.push(...this.regions);
    });

    this.baseService.get('legal-entities').subscribe(res => {

      this.legalEntitiesAllStatus =  <TreeNode[]> this.flatToHierarchy(res.body.data);

      this.flatLE = this.filterLE(res.body.data);
      let hierarchyLe = this.flatToHierarchy(this.filterLE(res.body.data));
      hierarchyLe = this.sortLe(hierarchyLe);
      this.extractManagers(hierarchyLe);

      this.legalEntities = <TreeNode[]> hierarchyLe;
      this.legalEntitiesBackup = structuredClone(this.legalEntities);
      this.loadSavedLE();
      this.expandAll(this.selectedNodes);
    });
  }

  ngOnChanges(){
  }
  loadData(){
    this.riskCase = this.stepperService.getNewRisk();

    this.baseService.get('countries').subscribe(res => {
      this.countries = res.body.data;
      this.filteredCountries.push(...this.countries);
    });

    this.baseService.get('regions').subscribe(res => {
      this.regions = res.body.data;
      this.filteredRegions.push(...this.regions);
    });

    this.baseService.get('legal-entities').subscribe(res => {

      this.flatLE = res.body.data;
      let hierarchyLe = this.flatToHierarchy(res.body.data);
      hierarchyLe = this.sortLe(hierarchyLe);
      this.extractManagers(hierarchyLe);
      this.legalEntities = <TreeNode[]> hierarchyLe;
      this.legalEntitiesBackup = structuredClone(this.legalEntities);
      this.loadSavedLE();
      this.expandAll(this.selectedNodes);
    });
    this.updateSelectedNodes();

  }
  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(7, this.filterLE(this.checkedNodes), 'legalEntities')));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  nextStep(data) {
    this.stepperService.changeIndex(7, this.filterLE(data), 'legalEntities');
  }

  reset(){
    this.checkedNodes = this.riskCase.legalEntities;
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.updateSelectedNodes();
    this.rcsaSelected = false;
    this.biSelected = false;
    this.managerFiltered = '';
    this.rcsaFiltered = false;
    this.biFiltered = false;
    this.searchByWordString = '';
    this.selectedCountry = null;
    this.selectedRegion = null;
  }

  deselectAll(){
    this.checkedNodes = [];
    this.selectedNodes = [];
    this.legalEntities = this.doPartialSelection(this.legalEntities,this.checkedNodes);
    this.selectedNodes = this.filterSelectedTree(this.selectedNodes, this.checkedNodes);
    this.rcsaSelected = false;
    this.unselectAllByRcsa()
  }

  /* ---------------------   SEARCHING METHODS --------------------------*/
  searchByTerm(searchString: any, restoreOther?: boolean): void {
    //I just searched with empty results so I need to repopulate the legalEntities
    if(this.legalEntities && this.legalEntities.length == 0){
      this.legalEntities = this.restoreTree(this.legalEntities);
      this.restoreOtherFilters('word');
    }
    if(searchString.length >2){
      this.searchByWordString = searchString;
      this.legalEntities = this.filterByString(this.legalEntities, searchString);
    } else {
      this.searchByWordString = '';
      this.legalEntities = this.restoreTree(this.legalEntities);
      restoreOther = true;
    }
    if(restoreOther){
      this.restoreOtherFilters('word');
    }
    this.expandAll(this.legalEntities);
  }

  filterByRegion(event: any ){
    this.filteredRegions = [];
    const query = event.query;
    if (query.length > 1) {
      this.filteredRegions = this.regions.filter(x => x['name'].toLowerCase().includes(query.toLowerCase()));
    } else {
      this.filteredRegions.push(...this.regions);
    }
  }

  selectRegion(selectedRegion: any, restoreOther?: boolean): void {
    //clear country if region change
    if(this.selectedCountry && this.selectedCountry['region'].id !== this.selectedRegion.id){
      this.selectedCountry = null;
      this.clearCountry();
    }

    this.legalEntities = this.restoreTree(this.legalEntities);
    this.restoreOtherFilters('region');

    if(this.selectedRegion){
      let byRegion: TreeNode[] = [];
      this.selectNodesByRegion(this.legalEntities, byRegion, this.selectedRegion)
      this.legalEntities = this.filterSelectedTree(this.legalEntities, byRegion);
    } else {
      this.legalEntities = this.restoreTree(this.legalEntities);
      restoreOther = true;
    }

    if(restoreOther){
      this.restoreOtherFilters('region');
    }
    this.expandAll(this.legalEntities);
  }

  clearRegion(){
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.restoreOtherFilters('region');
  }

  filterByCountry(event: any){
    this.filteredCountries = [];
    if(this.selectedRegion){
      this.filteredCountries = this.countries.filter(x => x['region'] ? x['region'].id == this.selectedRegion.id: false);
    }
    const query = event.query;
    if (query.length > 1) {
      this.filteredCountries = this.countries.filter(x => x['name'].toLowerCase().includes(query.toLowerCase()));
    } else {
      this.filteredCountries.push(...this.countries);
    }
  }

  selectCountry(selectedCountry: any, restoreOther?: boolean): void {
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.restoreOtherFilters('country');
    if(this.selectedCountry){
      let byCountry: TreeNode[] = [];
      this.selectNodesByCountry(this.legalEntities, byCountry, this.selectedCountry)
      this.legalEntities = this.filterSelectedTree(this.legalEntities, byCountry);
    }  else {
      this.legalEntities = this.restoreTree(this.legalEntities);
      restoreOther = true;
    }
    if(restoreOther){
      this.restoreOtherFilters('country');
    }
    this.expandAll(this.legalEntities);
  }

  clearCountry(){
    this.selectedCountry = null;
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.restoreOtherFilters('country');
  }
  /* ---------------------   FILTERING METHODS --------------------------*/
  toggleFilterForManager(value: any) {
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.managerFiltered = value;
    if(value){
      this.legalEntities = this.legalEntities.filter(x => x['id'] == value);
    }
    this.restoreOtherFilters('manager');
    this.expandAll(this.legalEntities);
  }

  toggleFilterRcsa(){
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.rcsaFiltered = !this.rcsaFiltered;
    this.biFiltered = false;
    if(this.rcsaFiltered){
      this.expandAll(this.legalEntities);
      let byRcsa: TreeNode[] = [];
      this.selectNodesByRCSA(this.legalEntities, byRcsa, this.riskCase.mandate.risk_assessment_id);
      this.legalEntities = this.filterSelectedTree(this.legalEntities, byRcsa);
    } else  {
      this.legalEntities = this.restoreTree(this.legalEntities);
      this.restoreOtherFilters('rcsa');
      this.expandAll(this.legalEntities);
    }
    this.restoreOtherFilters('rcsa');
  }

  toggleSelectRcsa(){
    this.rcsaSelected = !this.rcsaSelected;
    if(this.biSelected){
      this .unselectAllByBi();
      this.biSelected = false;
    }

    if(this.rcsaSelected){
      this.expandAll(this.selectedNodes);
      //mark as checked the rcsa nodes in the edit tree
      this.selectNodesByRCSA(this.legalEntities, this.checkedNodes, this.riskCase.mandate.risk_assessment_id);

      //adds the rcsa nodes to the view tree + the already selected
      this.selectedNodes = structuredClone(this.legalEntitiesBackup);
      let selectedByRCSA = [];
      this.selectNodesByRCSA(this.selectedNodes, selectedByRCSA, this.riskCase.mandate.risk_assessment_id);

      //checkedNodes already contains the selectedByRcsa
      this.selectedNodes = this.filterSelectedTree(this.selectedNodes, this.checkedNodes.concat(selectedByRCSA));
    } else {
      this.unselectAllByRcsa()
    }
    this.expandAll(this.selectedNodes);
  }

  unselectAllByRcsa() {
    this.checkedNodes = this.checkedNodes.filter(node => {
      return !node['risk_assessments'].includes(this.riskCase.mandate.risk_assessment_id);
    })
    this.legalEntities = this.doPartialSelection(this.legalEntities,this.checkedNodes);
    this.selectedNodes = this.filterSelectedTree(this.selectedNodes, this.checkedNodes);
  }

  toggleFilterBusinessImpact(){
    this.legalEntities = this.restoreTree(this.legalEntities);
    this.biFiltered = !this.biFiltered;
    this.rcsaFiltered = false;
    if(this.biFiltered){
      this.expandAll(this.legalEntities);
      let byBi: TreeNode[] = [];
      this.selectNodesByBi(this.legalEntities, byBi, this.riskCase.businessImpact.asListOfId());
      this.legalEntities = this.filterSelectedTree(this.legalEntities, byBi);
    } else  {
      this.legalEntities = this.restoreTree(this.legalEntities);
      this.restoreOtherFilters('bi');
      this.expandAll(this.legalEntities);
    }
    this.restoreOtherFilters('bi');
  }

  toggleSelectBusinessImpact(){
    this.biSelected = !this.biSelected;
    if(this.rcsaSelected) {
      this.unselectAllByRcsa()
      this.rcsaSelected = false;
    }

    if(this.biSelected){
      this.expandAll(this.selectedNodes);
      // checks the rcsa nodes in the edit tree
      this.selectNodesByBi(this.legalEntities, this.checkedNodes, this.riskCase.businessImpact.asListOfId());

      // adds the rcsa nodes to the view tree
      this.selectedNodes = structuredClone(this.legalEntitiesBackup);
      let selectedByBi = [];
      this.selectNodesByBi(this.selectedNodes, selectedByBi, this.riskCase.businessImpact.asListOfId());

      this.selectedNodes = this.filterSelectedTree(this.selectedNodes, this.checkedNodes.concat(selectedByBi));
    } else {
      this.unselectAllByBi();
    }
    this.expandAll(this.selectedNodes);
  }

  unselectAllByBi() {
      let bi = this.riskCase.businessImpact.asListOfId();
      this.checkedNodes = this.checkedNodes.filter(node => {
        return !node['business_impacts'].some(i => bi.includes(i));
      })
      this.legalEntities = this.doPartialSelection(this.legalEntities,this.checkedNodes);
      this.selectedNodes = this.filterSelectedTree(this.selectedNodes, this.checkedNodes);
  }

  //adds the nodes to the view tree
  updateSelectedNodes(){
    this.selectedNodes = structuredClone(this.legalEntitiesBackup);
    this.selectedNodes = this.filterSelectedTree(this.selectedNodes, this.checkedNodes);
    this.expandAll(this.selectedNodes);
  }

  manageNodeParent(selectedNode: TreeNode, checked: boolean){
    //selects the parent if the child is selected
    if(checked && selectedNode['node'].parent && this.checkedNodes.findIndex(x => x === selectedNode['node'].parent) === -1){
      this.checkedNodes.push(selectedNode['node'].parent);
      if(selectedNode['node'].parent.parent && this.checkedNodes.findIndex(x => x === selectedNode['node'].parent.parent) === -1){
        this.checkedNodes.push(selectedNode['node'].parent.parent);
      }
    }
    //unselect parent from checkedNodes if has no children selected and it's a manager responsible owner
    if(!checked && selectedNode['node'].parent && selectedNode['node'].parent['level'] == 1
      && !this.checkedNodes.some( node => selectedNode['node'].parent['children'].includes(node))){
      this.checkedNodes = this.checkedNodes.filter(node => node['id'] !== selectedNode['node'].parent['id']);
    }
    //partial selection of the parent
    this.doPartialSelection(this.legalEntities, this.checkedNodes);
  }

  /* ---------------------   PRIVATE METHODS --------------------------*/

  private loadSavedLE(){
    this.addLE(this.legalEntities, this.checkedNodes, <TreeNode[]>this.riskCase.legalEntities);
    this.selectedNodes =  structuredClone(this.legalEntities);
    this.selectedNodes = this.filterSelectedTree(this.selectedNodes, <TreeNode[]>this.riskCase.legalEntities);
  }

  //adds to the checkedNodes the ones belonging to the rcsa in input
  private selectNodesByRCSA(tree: TreeNode[], checkedNodes: TreeNode[], rcsaId: number, parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      node.partialSelected= false;
      if(parent){
        node.parent = parent;
      }
      if (node['risk_assessments'].includes(rcsaId)) {
        if(checkedNodes.findIndex(x => x === node) === -1){
          checkedNodes.push(node);
        }
        count--;
      }
      if (node.children){
        this.selectNodesByRCSA(node.children, checkedNodes, rcsaId, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        tree[0].parent.partialSelected = (count > 0 && count != tree.length);
      }
    }
    return tree;
  }

  //adds to the checkedNodes the ones belonging to the selected business impact
  private selectNodesByBi(tree: TreeNode[], checkedNodes: TreeNode[], bi: any[], parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      node.partialSelected= false;
      if(parent){
        node.parent = parent;
      }

      if (node['business_impacts'].some(i => bi.includes(i))) {
        if(checkedNodes.findIndex(x => x === node) === -1){
          checkedNodes.push(node);
        }
        count--;
      }
      if (node.children){
        this.selectNodesByBi(node.children, checkedNodes, bi, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        tree[0].parent.partialSelected = (count > 0 && count != tree.length);
      }
    }
    return tree;
  }

   //adds to the checkedNodes the ones belonging to Region
   private selectNodesByRegion(tree: TreeNode[], checkedNodes: TreeNode[], region: any, parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      node.partialSelected= false;
      if(parent){
        node.parent = parent;
      }
      if (node['region_id'] == (region.id)) {
        if(checkedNodes.findIndex(x => x === node) === -1){
          checkedNodes.push(node);
        }
        count--;
      }
      if (node.children){
        this.selectNodesByRegion(node.children, checkedNodes, region, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        tree[0].parent.partialSelected = (count > 0 && count != tree.length);
      }
    }
    return tree;
  }

  //adds to the checkedNodes the ones belonging to Country
  private selectNodesByCountry(tree: TreeNode[], checkedNodes: TreeNode[], country: any, parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      node.partialSelected= false;
      if(parent){
        node.parent = parent;
      }
      if (node['country_id'] && node['country_id'] ==(country.id)) {
        if(checkedNodes.findIndex(x => x === node) === -1){
          checkedNodes.push(node);
        }
        count--;
      }
      if (node.children){
        this.selectNodesByCountry(node.children, checkedNodes, country, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        tree[0].parent.partialSelected = (count > 0 && count != tree.length);
      }
    }
    return tree;
  }

  //removes from checkNodes the ones belonging to the rcsa in input
  private doPartialSelection(tree: TreeNode[], checkedNodes: TreeNode[], parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      node.partialSelected = false;
      if(parent){
        node.parent = parent;
      }
      if (checkedNodes.includes(node)) {
        count--;
      }
      if (node.children){
        this.doPartialSelection(node.children, checkedNodes, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        tree[0].parent.partialSelected = (count > 0 && count != tree.length);
      }
    }
    return tree;
  }

  //filters tree leaving just treeToCompareTo nodes
  //tree is a hierarchical structure
  //flatToCompareTo is a flat list
  private filterSelectedTree(tree: TreeNode[], flatToCompareTo: TreeNode[], parent?: TreeNode) {
    for (const node of tree) {
      if (node.children){
        node.children = this.filterSelectedTree(node.children, flatToCompareTo, node);
      }
      //no children selected and not selected itself and manager responsible owner node
      if(this.hasNoChildren(node) && (!flatToCompareTo.some(e => e['id'] == node['id']) || node['level'] == 1)){
          tree = tree.filter(x => x !== node);      }
    }
    return tree;
  }

  private hasNoChildren(node: TreeNode) {
    return (!node.children || (node.children && node.children.length == 0));
  }

  private flattenTree(tree: TreeNode[], flatten: TreeNode[]) {
    for (const node of tree) {
      if (node.children){
        this.flattenTree(node.children, flatten);
      }
      flatten.push(node);
    }
  }

  //mark as checked the nodes in tree that are saved in legalEntities. Fills checkedNodes with the selected nodes
  private addLE(tree: TreeNode[], checkedNodes: TreeNode[], legalEntities: TreeNode[], parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      if(parent){
        node.parent = parent;
      }
      if(legalEntities.some(e => e['id'] == node['id'] )){
        if(checkedNodes.findIndex(x => x === node) === -1){
          checkedNodes.push(node);
        }
        count--;
      }

      if (node.children){
        this.addLE(node.children, checkedNodes, legalEntities, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        let isPartiallySelected = (count > 0 && count != tree.length);
        tree[0].parent.partialSelected = isPartiallySelected;
      }
    }
  }

  //restores the tree passed as parameter from the original saved backup structure
  private restoreTree(tree: TreeNode[]){
    tree = structuredClone(this.legalEntitiesBackup);
    let newCheckedNodes = [];
    this.addLE(tree, newCheckedNodes, <TreeNode[]>this.checkedNodes);
    this.checkedNodes = newCheckedNodes;
    return tree;
  }

  private restoreOtherFilters(exclude: string){
    if(exclude!== 'manager' && this.managerFiltered){
      this.legalEntities = this.legalEntities.filter(x => x['id'] == this.managerFiltered);
    }
    if (exclude!== 'rcsa' && this.rcsaFiltered){
      let byRcsa: TreeNode[] = [];
      this.selectNodesByRCSA(this.legalEntities, byRcsa, this.riskCase.mandate.risk_assessment_id);
      this.legalEntities = this.filterSelectedTree(this.legalEntities, byRcsa);
    }
    if (exclude!== 'bi' && this.biFiltered){
      let byBi: TreeNode[] = [];
      this.selectNodesByBi(this.legalEntities, byBi, this.riskCase.businessImpact.asListOfId());
      this.legalEntities = this.filterSelectedTree(this.legalEntities, byBi);
    }
    if(exclude!=='word' && this.searchByWordString !== ''){
      this.searchByTerm(this.searchByWordString, false);
    }
    if(exclude!=='country' && this.selectedCountry ){
      this.selectCountry(this.selectedCountry, false);
      return;
    }
    if(exclude!=='region' && this.selectedRegion && !this.selectedCountry){
      this.selectRegion(this.selectedRegion, false);
    }
  }

  private filterByString(tree: TreeNode[], searchString: string, parent?: TreeNode) {
    for (const node of tree) {
      if (node.children){
        node.children = this.filterByString(node.children, searchString, node);
      }
      const searchByName = node['name'].toLowerCase().includes(searchString.toLowerCase());
      const searchByFsa = node['fsa_bp_no'] ? node['fsa_bp_no'].toLowerCase().includes(searchString.toLowerCase()) : false;
      const found = searchByName || searchByFsa;
      //no children selected and not selected itself
      if((!node.children && !found) ||    //leaf
        (node.children && node.children.length == 0 && !found)){
          tree = tree.filter(x => x !== node);
      }
    }
    return tree;
  }

  private flatToHierarchy (flat:any) : any[] {
    let roots = []; // things without parent
    let all = {};

    //need to change type attribute because it's interfering with the p-tree template
    flat = JSON.parse(JSON.stringify(flat).split('"type":').join('"_type":'));

    flat.forEach(function(item) {
      all[item.id] = item;
    })

    // split roots apart and connect children to its parent
    Object.keys(all).forEach(function (id) {
        let item = all[id];
        if (item.parent_id === null) {
            roots.push(item);
        } else if (item.parent_id in all) {
            let p = all[item.parent_id];
            if (!('children' in p)) {
                p.children = [];
            }
            p.children.push(item);
        }
    })

    return roots
  }

  sortLe(toBeSorted: any[]) : any[]{
    toBeSorted.forEach( node => {
      if (node.children){
        //sort alphabetically by name
        node.children = node.children.sort((a, b) => a.name.localeCompare(b.name));
        this.sortLe(node.children);
      }
    });
    return toBeSorted;
  }

  private expandAll(checkednodes : TreeNode[]){
    checkednodes.forEach( node => {
        this.expandRecursive(node, true);
    } );
  }

  private contractAll(checkednodes : TreeNode[]){
    checkednodes.forEach( node => {
        this.expandRecursive(node, false);
    } );
  }

  private expandRecursive(node:TreeNode, isExpand:boolean){
    node.expanded = isExpand;
    if (node.children){
        node.children.forEach( childNode => {
            this.expandRecursive(childNode, isExpand);
        } );
    }
  }

  private extractManagers(data: any) {
    data.forEach(element => {
      this.managerList.push({label:element.name, value:element.id});
    });
  }

  changeData(){
    this.newData.emit({prop: 'legalEntities', propValue: this.filterLE(this.checkedNodes)});
  }

  toggleSkipDialog(){
    if(this.selectedNodes.length > 0){
      this.displaySkipDialog = !this.displaySkipDialog;
    } else {
      this.nextStep(null);
    }
  }

  clearData(){
    this.selectedNodes = [];
    this.nextStep(this.selectedNodes);
  }
  tooltip(toolTipontant){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'right',  theme: 'light', content: toolTipontant };
    return tooltipData;

  }
  filterLE(list){
    let newList = [];
    if(list){
      list.forEach(element => {
        if(element.legal_entity_status == "Active" || element.legal_entity_status == 'Run-Off'){
          newList.push(element);
        }
      });
      return newList;

    }else{
      return null;

    }

  }
}

import { Component, OnInit,TemplateRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseService,StepperService } from '@shared/services';
import { Router } from '@angular/router';
import { RiscCaseRequest as RiskCaseMessage } from '@shared/models/dto/risk-case-request.dto';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewRisk } from '@shared/models/new-risk.model';
import { Issue } from 'src/app/shared/models/issue.model';
import { PrimaryParentType, RISK_CASE_ID_PARAM_MARK } from '@shared/common/constants';
import { issueStepList } from '../../../../../common-constants';


@Component({
  selector: 'app-issue-from-event',
  templateUrl: './issue-from-event.component.html',
})
export class IssueFromEventComponent implements OnInit,OnChanges {
  newIssue: NewRisk = new NewRisk();
  permalinkText = 'not available';
  etag: string;
  modalRef: BsModalRef;
  @Input() eventData: any;
  @Input() eventId: any;

  @Input() mandate: any;
  newIndex = 2;
  steps = [
    { label: 'Mandate', data: null, url: '/new-risk/mandate' },
    { label: 'Issue', data: null, url: '/new-risk/event' },
    { label: 'Description', data: null, url: '/new-risk/description' },
    { label: 'Identification', data: null, url: '/new-risk/identification' },
    { label: 'Risk & Control', data: null, url: '/new-risk/riskAndControl' },
    { label: 'Define Roles', data: 'businessImpact', url: '/new-risk/roles' },
    { label: 'Finalize Before Go-Live', data: null, url: '/new-risk/draftcreated' },
];
  constructor(private modalService: BsModalService, private baseService: BaseService,private router: Router,private stepperService: StepperService) {

  }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  setData(issueData){
    this.newIssue.lastStep= issueData.index;
    this.newIssue.lastWizardStep = issueData.lastWizardStep;

    issueData.listOfData.forEach(element => {
      this.newIssue[element.attr] = element.issueData;
    });
    this.save();
  }
  closeModel(){
    this.modalRef.hide();

  }
  openModal(template: TemplateRef<any>) {
    this.newIndex = 2;

    this.newIssue = new NewRisk();
    this.newIssue.status = 38;
    this.newIssue.issue = new Issue();
    this.newIssue.lastStep = 2;
    if(this.eventData){
      this.newIssue.primary_parent_type_id = PrimaryParentType.RiskAssessment;
      this.newIssue.primary_parent_id = this.eventData[0].id;


   }
    if(this.mandate){
     this.newIssue.mandate = this.mandate;


   }
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: ' modal-lg modal-primary IssueModal',
    };
    this.modalRef = this.modalService.show(template, configs);
  }

save() {
    let req = RiskCaseMessage.toDto(this.newIssue);
    if (this.newIssue.riskCaseId !== undefined) {
        const  permalinkText = window.location.origin + this.steps[this.newIndex].url + RISK_CASE_ID_PARAM_MARK + this.newIssue.riskCaseId;
        req.risk_case.permalink = permalinkText;
        this.baseService.put('risk-case', req, this.etag, this.newIssue.riskCaseId).subscribe(data => {
            this.newIndex = this.newIssue.lastStep;
            if(this.newIndex == 99){
              this.closeModel();
            }
            this.etag = data.headers.get('ETag').replace(/"/g, '');
            let riskCaseUpdatedData = data.body.risk_case;
            if (data.body.risks_primary_path) { riskCaseUpdatedData.risk = data.body.risks_primary_path; }
            if (data.body.risk_assessments_primary_path) { riskCaseUpdatedData.risk_assessments = data.body.risk_assessments_primary_path; }
            this.newIssue = RiskCaseMessage.toModel(riskCaseUpdatedData);

        }, error => {
            console.log(error);
        },
            () => {
            });
    } else {
        this.baseService.post('risk-case', req.risk_case).subscribe(data => {
            this.newIndex = this.newIssue.lastStep;
            this.etag = data.headers.get('ETag').replace(/"/g, '');
            this.newIssue = RiskCaseMessage.toModel(data.body);
            this.newIssue.roles = { eventOwner: data.body.owner };
            this.newIssue.riskCaseId = data.body.id;
            this.permalinkText = this.permalinkText.replace('undefined', this.newIssue.riskCaseId + '');
            let linkedIssue = {"parentRiskCaseId": this.eventId, "riskCaseId": this.newIssue.riskCaseId};
            this.baseService.post('risk-case-association', linkedIssue).subscribe(resultData => {
              this.loadRiskCaseById(this.newIssue.riskCaseId);


          }, error => {
              console.log(error);
          },
              () => {
              });




        }, error => {
            console.log(error);
        },
            () => {
            });
    }
}
title(index){
  return issueStepList[index].label;

}
loadRiskCaseById(id: number) {

  this.baseService.get('risk-case/' + id).pipe(
  ).subscribe(
      res => {

          this.etag = res.headers.get('ETag').replace(/"/g, '');
          const riskCaseUpdatedData = res.body.risk_case;
          riskCaseUpdatedData.risk_assessments = [];
          if (res.body.risks_primary_path) { riskCaseUpdatedData.risk = res.body.risks_primary_path; }
          if (res.body.risk_assessment) { riskCaseUpdatedData.risk_assessments.push(res.body.risk_assessment); }
          if (res.body.risk_assessments_primary_path) {
              riskCaseUpdatedData.risk_assessments = this.getUnique(riskCaseUpdatedData.risk_assessments.concat(res.body.risk_assessments_primary_path), 'id');
          }
          this.newIssue = RiskCaseMessage.toModel(riskCaseUpdatedData);


      },
      errorData => {

      }
  );

}
getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr.map(e => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);
  return unique;
}
}

import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, OnDestroy } from '@angular/core';
import { MonetaryImpact } from '../../../../../shared/models/monetaryImpact';
import { Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewRisk } from '../../../../../shared/models/new-risk.model';
import { StepperService , BaseService} from '../../../../../shared/services';
import { Subscription } from 'rxjs';
import { dateFormat } from '@shared/common/constants';

@Component({
  selector: 'app-additional-information-step',
  templateUrl: './additional-information-step.component.html',
  styleUrls: ['./additional-information-step.component.scss']
})
export class AdditionalInformationStepComponent implements OnInit, OnDestroy {
  @Input() viewButton: boolean = true;
  @Input() rootCause: boolean = false;
  @Input() location: boolean = true;
  @Input() Function: boolean = true;

  @Output() newData: EventEmitter<any> = new EventEmitter<any>();


  viewStatus: string;
  selectedOutsourcing: string;
  modalRef: BsModalRef;
  showAddCashOutBtn = false;
  CaptureRecoveryItem: any;
  RootCause = [];
  today = new Date();
  dateFormat = dateFormat;
  RootCause2 = [];
  manadtaryTooltipText = 'This section mandatory to fill in once you record a cash-out';
  selectedList: Array<MonetaryImpact>;
  position: TooltipPosition = 'before';
  searchValue: string;

  searchValueRCL2: string;
  searchValueCF: string;
  searchValueL: string;
  riskCase: NewRisk = new NewRisk();
  FieldHelpData;
  monetaryImpactItem: MonetaryImpact  = new MonetaryImpact();
  additionalInformationList = [
    {title: 'Function' , list: [], placeHolder: 'Function Search' },
    {title: 'Event Location (Country)' , list: [], placeHolder: 'Event Location (Country) Search'}
  ];
  allFunctions = [];
  countryListAllIsoData = [];

  displaySkipDialog = false;
  subscription: Subscription = new Subscription();

  constructor(private stepperService: StepperService, private modalService: BsModalService, private router: Router,
              private baseService: BaseService ) {
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.viewStatus = 'additionalInformation';
    this.riskCase = this.stepperService.newRisk;

    if (this.riskCase.root_cause_level_1){
      this.baseService.get('root-causes/level2/' + this.riskCase.root_cause_level_1.id).subscribe(res => {
        this.RootCause2 = res.body.data;
       });
    }
    this.baseService.get('risk-case/'+this.riskCase.riskCaseId +'/monetary-impacts').subscribe(res => {
      this.selectedList = res.body.data;
      });

    this.baseService.get('countries').subscribe(res => {
      this.countryListAllIsoData = res.body.data;
      });
    this.baseService.get('enum/items/13').subscribe(res => {
        this.allFunctions = res.body.data;
        });

    this.baseService.get('root-causes/level1').subscribe(res => {
      this.RootCause = res.body.data;
    });


  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.updateRiskCase(this.riskCase)));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  chechEnableRadioBtn(value, array) {

    for (const iterator of array) {
      if (iterator.monetary_impact_type.id === value) {
        return true;
      }
    }
    return false;
  }
  sumCashOut(item){
  let sumation = 0;
  let captureRecoverySumation = 0;
  if (item.captured_recoveries){
    item.captured_recoveries.forEach(element => {
      captureRecoverySumation = captureRecoverySumation + element.amount;
    });
  }

  sumation = item.amount - captureRecoverySumation ;
  return sumation;
  }
  tooltipText(){
    let tooltipText = '';
    if (this.additionalInformationMandatoryFlag()){
      tooltipText = this.manadtaryTooltipText;
    }
    return tooltipText;
  }
  additionalInformationMandatoryFlag(){
    if (
      this.riskCase.company_function ||
      this.riskCase.location
    ){
      return false;
    }else{
      return true;

    }
  }
setAdditionalInformationData(value, property){
  this.riskCase[property] = value;
  this.newData.emit({prop: property, propValue: value});
  this.additionalInformationMandatoryFlag();
}
  setRootCause2(data){


    if(data){

      this.newData.emit({prop: 'root_cause_level_1', propValue: {id: data.level_1_id}});

      this.riskCase.root_cause_level_1 = {id: data.level_1_id, name: data.level_1_name};
      this.additionalInformationMandatoryFlag();

      this.baseService.get('root-causes/level2/' + data.level_1_id).subscribe(res => {
        this.RootCause2 = res.body.data;
       });
    }else{
      this.newData.emit({prop: 'root_cause_level_1', propValue: null});
      this.riskCase.root_cause_level_1 = null;

      this.RootCause2 = [];
    }

    this.setAdditionalInformationData(null, 'root_cause_level_2');
  }

  nextStep(data) {
    if (data){
      this.riskCase.lastStep = 10;
      this.stepperService.currentStepData.index = 10;
      this.stepperService.updateRiskCase(this.riskCase);
    }else{
      this.stepperService.changeIndex(10, null);

    }
    this.router.navigate(['/new-risk/go-live']);
  }
  changeViewStatus(status: string) {
    this.viewStatus = status;
  }
   // search
   Search(payLoad: any): void {
    this.searchValue = payLoad;

  }
  addItem(monetaryImpactType){
    this.showAddCashOutBtn  = false;

    const monetaryImpactItem = {
      amount: 0,
      type: monetaryImpactType,
      bus_date: new Date(),
      comment: '',
      captured_recoveries: []
    };

    this.monetaryImpactItem =  new MonetaryImpact();

  }
  cantEdit(event): boolean {
    return false;
  }

  dateChanged(evt, row){
    const selectedDate = new Date(evt);
   }
   deletItem(index){
    this.selectedList.splice(index, 1);
   }
   deletCaptureRecoveryItem(i, index){
    this.selectedList[i].captured_recoveries.splice(index, 1);

   }
   addCaptureRecovery(i, dialog){
     if (!this.selectedList[i].captured_recoveries){
      this.selectedList[i].captured_recoveries = [];
     }
     // tslint:disable-next-line:max-line-length
     this.selectedList[i].captured_recoveries.push({monetary_impact_type: {id: 92}, amount: 0, bus_date: new Date (), insert_date: new Date (), comment: '' });
     this.CaptureRecoveryItem = this.selectedList[i].captured_recoveries;
   }


  openModal(template: TemplateRef<any>) {
       const configs = {
         keyboard: true,
         animated: true,
         class: ' modal-lg modal-primary RCSAModal',
         ignoreBackdropClick: false
       };
       this.modalRef = this.modalService.show(template, configs);
     }
     checkValiationOffListItems(list, attr){
      if (list.length === 0){
        return true;
      }

      for (const iterator of list) {

         for (const atr of attr) {
        if (!iterator[atr] || iterator[atr] === '' || iterator[atr] === 0 ){
          return false;
        }

      }

      }
      return true;
     }
     handelCashOut(list){
       if (!this.chechEnableRadioBtn('Cash-out', list)){
        this.monetaryImpactItem =  new MonetaryImpact();
        this.addItem('Cash-out');

       }else{
        this.showAddCashOutBtn  = true;
       }
     }



        // search
  SearchRCL2(payLoad: any): void {
    this.searchValueRCL2 = payLoad;
  }
  SearchCF(payLoad: any): void {
    this.searchValueCF = payLoad;
  }
  SearchL(payLoad: any): void {
    this.searchValueL = payLoad;
  }

  toggleSkipDialog(){
    if(!this.additionalInformationMandatoryFlag()){
      this.displaySkipDialog = !this.displaySkipDialog;
    } else {
      this.nextStep(false);
    }
  }

  clearData(){
    this.setAdditionalInformationData(null,"location" );
    this.setAdditionalInformationData(null,"company_function" );
    this.nextStep(true);
  }
  tooltip(toolTipontant){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'right',  theme: 'light', content: toolTipontant };
    return tooltipData;

  }
}

import { Component,Input,Output,EventEmitter, OnInit, OnDestroy,TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { StepperService } from '../../../../../shared/services/stepper.service';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { RiskCaseConstants } from '../../../common-constants';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-step5-issue',
  templateUrl: './step5-issue.component.html',
  styleUrls: ['./step5-issue.component.scss']
})
export class Step5IssueComponent implements OnInit, OnDestroy, OnChanges {
  viewStatus: string;
  searchValue: string;
  modalRef: BsModalRef;
  currentStatus;
  @Input() issueFromEvent: boolean = false;
  @Output() newIssueData: EventEmitter<any> = new EventEmitter<any>();  issueType: string;
  selectedRisk;
  selectedControl;
  risksLists = [];
  controlList = [];
  FieldHelpData;
  riskCase: NewRisk;
  RiskCaseConstants = RiskCaseConstants;
  @Input() viewButton: boolean = true;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  subscription: Subscription = new Subscription();
  constructor(private stepperService: StepperService,
              private modalService: BsModalService,
              private baseService: BaseService) {
    this.FieldHelpData = this.stepperService.FieldHelpData;
    this.viewStatus = 'mainView';
    if(!this.issueFromEvent){
      this.riskCase = stepperService.getNewRisk();
      this.currentStatus = this.riskCase.status;
    }
    if(this.riskCase.risk && !this.issueFromEvent){
      this.selectedRisk = this.riskCase.risk[0];
      this.issueType = 'Risk';
    }
    if(this.riskCase.controls && !this.issueFromEvent){
      if(this.riskCase.controls.length > 0){
        this.selectedControl ={"control_id":this.riskCase.controls[0].ctrl_id,"association_type":this.riskCase.controls[0].association_type,"assessors_names":this.riskCase.controls[0].assessors_names};
        this.issueType = 'Control';
      }
    }
    if(this.riskCase.mandate && this.riskCase.mandate.risk_assessment_id) {
      this.baseService.get('risks?riskAssessmentId=' + this.riskCase.mandate.risk_assessment_id).subscribe(res => {
        this.risksLists = res.body.data;
      });
    }
  }
  ngOnInit(): void {
    this.getcontrolList();
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  nextStepRisk(data) {
    if(this.stepperService.newRisk.primary_parent_type_id.id==442){
      if(this.issueFromEvent){
        this.newIssueData.emit(
          {lastWizardStep: 'RISK-OR-CONTROL',index:5,listOfData:
            [
              {attr:'primary_parent_type_id',issueData: {id:443, name:'Risk'}},
            {attr:'risk_assessments',issueData:[{id:this.stepperService.newRisk.primary_parent_id}]},
            {attr:'primary_parent_id',issueData: data.risk_id ? data.risk_id : data.id},
            {attr:'risk',issueData: [data]}]})
            return;
      }else{
        this.stepperService.newRisk.risk_assessments = [{id:this.stepperService.newRisk.primary_parent_id}];
      }
    }
    if(this.issueFromEvent){
      this.newIssueData.emit(
        {lastWizardStep: 'RISK-OR-CONTROL',index:5,listOfData:
          [
            {attr:'primary_parent_type_id',issueData: {id:443, name:'Risk'}},
          {attr:'primary_parent_id',issueData: data.risk_id ? data.risk_id : data.id},
          {attr:'risk',issueData: [data]}]}        )
    }else{
      this.stepperService.newRisk.primary_parent_id = data.risk_id ? data.risk_id : data.id;
      this.stepperService.newRisk.primary_parent_type_id = {id:443, name:'Risk'};
      this.stepperService.changeIndex(5, [data] , 'risk');
    }
  }
  nextStepControl(data) {
    if(this.stepperService.newRisk.primary_parent_type_id.id==442){
      if(this.issueFromEvent){
        this.newIssueData.emit(
          {lastWizardStep: 'RISK-OR-CONTROL',index:5,listOfData:
            [{attr:'primary_parent_type_id',issueData: {id:444, name:'Control'}},
              {attr:'risk_assessments',issueData:[{id:this.stepperService.newRisk.primary_parent_id}]},
              {attr:'primary_parent_id',issueData:this.selectedControl.control_id},
              {attr:'controls',issueData: [ {"rcId":this.riskCase.riskCaseId,"ctrl_id":this.selectedControl.control_id,"association_type":this.selectedControl.association_type},]}]}        )
      }else{
        this.stepperService.newRisk.risk_assessments = [{id:this.stepperService.newRisk.primary_parent_id}];
      }
    }
    if(this.issueFromEvent){
      this.newIssueData.emit(
        {lastWizardStep: 'RISK-OR-CONTROL',index:5,listOfData:
          [{attr:'primary_parent_type_id',issueData: {id:444, name:'Control'}},
            {attr:'primary_parent_id',issueData:this.selectedControl.control_id},
            {attr:'controls',issueData: [ {"rcId":this.riskCase.riskCaseId,"ctrl_id":this.selectedControl.control_id,"association_type":this.selectedControl.association_type},]}]}        )
    }else{
      this.stepperService.newRisk.primary_parent_id = this.selectedControl.control_id;
      this.stepperService.newRisk.primary_parent_type_id = {id:444, name:'Control'};
      this.stepperService.changeIndex(5, [ {"rcId":this.riskCase.riskCaseId,"ctrl_id":this.selectedControl.control_id,"association_type":this.selectedControl.association_type}] , 'controls');
    }
  }
  onChangeRBRisk(event) {
    this.selectedRisk = event;
    this.stepperService.newRisk.controls = [];
    this.selectedControl = null;
    this.newData.emit({prop: 'risk', propValue: this.selectedRisk});
    this.newData.emit({prop: 'controls', propValue: []});
  }
  onChangeRBControl(event) {
    this.selectedControl = event;
    this.stepperService.newRisk.risk = null;
    this.selectedRisk = null;
    this.newData.emit({prop: 'controls', propValue:[ {"rcId":this.riskCase.riskCaseId,"ctrl_id":this.selectedControl.control_id,"association_type":this.selectedControl.association_type}]});
    this.newData.emit({prop: 'risk', propValue: null});
  }
  getcontrolList(){
    this.baseService.get('controls/' + this.riskCase.mandate.risk_assessment_id).subscribe(res => {
      this.controlList = res.body.data;
    });
  }
  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;
  }
  selectIssueType(type,templet,validationObject){
    if(validationObject){
      this.openModal(templet);
    }else{
      this.issueType = type;
    }
  }
  getControl(ctrlId){
    let obj ;
    if(this.controlList.find(o => o.control_id === ctrlId)){
      obj = this.controlList.find(o => o.control_id === ctrlId);
    }
    return obj;
  }
  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary RCSAModal',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(template, configs);
  }
  tooltip(toolTipontant){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'right',  theme: 'light', content: toolTipontant };
    return tooltipData;
  }
  ngOnChanges(changes: SimpleChanges): void {
      if(this.issueFromEvent){
        this.currentStatus = 38;

        this.selectedRisk = null;
        this.selectedControl = null;
      }
  }
}

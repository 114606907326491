<p class=" t-demo__btn">
    The Group Risk Impact Assessment of the issue is performed at the time when the issue has been raised.
    <br> It indicates the potential loss to Swiss Re Group should the impacted risk(s) occur.
</p>

<div *ngIf='viewButton' class="w-100 position-relative">


    <div class=" my-3 accordionContainerHeight">





        <ng-container>
            <mat-accordion class="fix-radio-position" hideToggle="true">

                <mat-expansion-panel *ngFor='let item of controlRisks ' [ngClass]="  item.associationType == 'PRIMARY'   ?'PRIMARYcontrolRisks':''">
                    <mat-expansion-panel-header>
                        <mat-panel-description class='d-flex' style="align-items: center;">

                            <div class="PRIMARYcontrolRisksTitle"> {{item.rkName}}: {{item.rkDesc}} </div>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p class="PRIMARYcontrolRisksContant">Description: {{item.rkDetailDesc}}</p>
                    <p class="PRIMARYcontrolRisksContant" *ngIf="item.folder">Folder: {{item.folder}}</p>

                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </div>

    <p class=" t-demo__btn">
        Based on these risks, please rate the impact on all impact dimensions:
    </p>
    <div class="flex-start d-flex justify-content-start my-3 mx-0">
        <div class=" m-2 mt-4 containerWidth">
            <div class="radioBtnBodyContainer">
                Number ranges in USD
            </div>
        </div>
        <div *ngFor='let item of impactRateList ' class="m-4 containerWidth">
            <div *ngIf='item.id!= 261' class="radioBtnBodyContainer">
                {{item.name}}
            </div>
        </div>

    </div>

    <div class="flex-start d-flex justify-content-start my-7 mx-0" *ngFor='let itemType of typeRateList; let i = index '>
        <div class=" m-2 containerWidth">
            {{itemType.name}}
        </div>
        <div *ngFor='let item of impactRateList ' class="m-4 containerWidth">
            <label class="container">
                    <input type="radio" [checked]="checkSelection(item,itemType)" (change)='selectUser(item,itemType,i+1)' [name]="'radio'+itemType.name">
                    <div class="checkmark">
                        <div class="radioBtnBodyContainer">
                            <ng-container *ngIf='item.id == 261'>
                                {{item.name}}
                               </ng-container>

                           <ng-container *ngIf='itemType.id== 262 && item.id == 19'>
                            > 20 - 50m


                           </ng-container>

                           <ng-container *ngIf='itemType.id== 262 && item.id == 20'>
                            > 10 - 20m

                           </ng-container>


                           <ng-container *ngIf='itemType.id== 262 && item.id == 22'>
                            > 0 - 10m

                           </ng-container>

                           <ng-container *ngIf='itemType.id== 262 && item.id == 18'>
                            > 50m
                           </ng-container>






                           <ng-container *ngIf='itemType.id== 263 && item.id == 19'>
                            > 80 - 200m
                           </ng-container>

                           <ng-container *ngIf='itemType.id== 263 && item.id == 20'>
                            > 40 - 80m


                           </ng-container>


                           <ng-container *ngIf='itemType.id== 263 && item.id == 22'>
                            > 0 - 40m

                           </ng-container>

                           <ng-container *ngIf='itemType.id== 263 && item.id == 18'>
                            > 200m
                           </ng-container>





                           <ng-container *ngIf='itemType.id== 264 && item.id == 19'>
                            Loss of some large accounts; limited national media coverage; some impact on share price; elevated impact on reputation.


                           </ng-container>

                           <ng-container *ngIf='itemType.id== 264 && item.id == 20'>
                            Stakeholder dissatisfaction and complaints leading to withdrawn business; some industry media coverage; moderate impact on reputation.



                           </ng-container>


                           <ng-container *ngIf='itemType.id== 264 && item.id == 22'>
                            Increase in stakeholder dissatisfaction and complaints, but impact restricted to specific stakeholders; minor impact on reputation.



                           </ng-container>

                           <ng-container *ngIf='itemType.id== 264 && item.id == 18'>
                            Large customer loss; sustained national media coverage; significant impact on share price; significant impact on reputation.

                           </ng-container>







                           <ng-container *ngIf='itemType.id== 265 && item.id == 19'>

                            Elevated potential for public warning and fines expected; significant supervisory scrutiny with repeated inspections.

                           </ng-container>

                           <ng-container *ngIf='itemType.id== 265 && item.id == 20'>
                            Increased supervisory scrutiny or private warnings by supervisors.



                           </ng-container>


                           <ng-container *ngIf='itemType.id== 265 && item.id == 22'>
                            Minor compliance impact.



                           </ng-container>

                           <ng-container *ngIf='itemType.id== 265 && item.id == 18'>
                            High potential for public warning and significant fines expected. Intensive inspection by supervisors with high visibility; potential withdrawal of license.
                        </ng-container>









                        </div>
                    </div>
                </label>
        </div>



    </div>

    <div class="flex-start d-flex justify-content-start my-3 mx-0">
        <div class=" m-2 containerWidth">


        </div>
        <div *ngFor='let item of impactRateList ' class="m-4 containerWidth">
            <div *ngIf='item.id == 18' class="radioBtnBodyContainer IRResult">
                Issue Impact (calculated):
            </div>
            <div *ngIf='item.id == 261' class="radioBtnBodyContainer IRResult">
                <span class="IRResult"> {{getResult()}}  </span>
            </div>
        </div>

    </div>
</div>
